import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
} from '@mui/material'
import CustomCardHeader from '~/components/CustomCardHeader'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useGetAllPermissionsQuery } from '../query'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import LoadingCircle from '~/components/LoadingCircle'
import PropTypes from 'prop-types'
import Body from '../Body'
import { useTranslation } from 'react-i18next'
import { useUpdateMutation } from '../mutate'

const RoleDetailsPermissionTag = ({ setBreadCrumbName, data, isLoading }) => {
    const localization = 'pages.administrator.roleDetail'
    const { t } = useTranslation()

    const { mutate: updateRole } = useUpdateMutation({})
    const urlParams = useParams()
    const queryResponse = useMemo(() => {
        return data?.data || []
    }, [data])
    const { data: permissions, isLoading: isLoadingPermissions } =
        useGetAllPermissionsQuery(queryResponse.guard_name)
    const initialValues = useMemo(() => {
        let permissionValues = []
        const permissionNamesOfRole = queryResponse.permissions
            ? queryResponse.permissions.map((permission) => {
                  return permission.name
              })
            : []
        if (permissions) {
            permissionValues = permissions.map((permission) => {
                let value = false
                if (permissionNamesOfRole.indexOf(permission.name) !== -1) {
                    value = true
                }
                return { name: permission.name, value }
            })
        }
        return {
            ...queryResponse,
            permissions: permissionValues,
        }
    }, [permissions, queryResponse])

    if (isLoading || isLoadingPermissions) {
        return <LoadingCircle />
    }

    return (
        <Grid container spacing={2}>
            <Stack direction='column' spacing={3} sx={{ width: '100%' }}>
                <Card>
                    <Form
                        onSubmit={updateRole}
                        mutators={{
                            ...arrayMutators,
                        }}
                        initialValues={initialValues}
                        render={({ values, ...props }) => (
                            <form onSubmit={props.handleSubmit}>
                                <CustomCardHeader
                                    title={`${t(`${localization}.pageName`)}: ${
                                        urlParams.id
                                    }`}
                                    action={
                                        <Box
                                            direction='row'
                                            alignItems='center'
                                            justifyContent='center'
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 0.5,
                                            }}
                                        >
                                            <Button
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    textTransform: 'none',
                                                    backgroundColor: '#76B72A',
                                                    marginRight: 0.5,
                                                    marginTop: 0.5,
                                                }}
                                                type={`submit`}
                                            >
                                                {t(`${localization}.save`)}
                                            </Button>
                                        </Box>
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Body
                                            data={queryResponse}
                                            localization={localization}
                                        />
                                    </Grid>
                                </CardContent>
                            </form>
                        )}
                    />
                </Card>
            </Stack>
        </Grid>
    )
}
RoleDetailsPermissionTag.propTypes = {
    handleSubmit: PropTypes.func,
    setBreadCrumbName: PropTypes.func,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
}

export default RoleDetailsPermissionTag
