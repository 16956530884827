import { Grid, Typography } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import { ADMINISTRATOR_ROLE_CREATE } from '~/constants/Routes'
import CheckboxesField from '../../../components/FormInput/CheckboxesField'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const pagePath = ADMINISTRATOR_ROLE_CREATE
function Body({ data, localization }) {
    const { t } = useTranslation()
    return (
        <Grid container item spacing={2} sx={{ padding: '16px 0px 0px 16px' }}>
            {data ? (
                <>
                    <Grid item xs={12}>
                        <InputField
                            localization={localization}
                            name='guard_name'
                            componentId={`role_form_guard_name`}
                            pagePath={pagePath}
                            disabled={true}
                            labelStyle={{ fontWeight: '900' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            localization={localization}
                            name='name'
                            componentId={`role_form_name`}
                            pagePath={pagePath}
                            disabled={true}
                            labelStyle={{ fontWeight: '900' }}
                        />
                    </Grid>
                    <CheckboxesField
                        localization={localization}
                        name={`permissions`}
                        styledForLabel={{
                            width: '100%',
                            paddingLeft: '16px',
                            paddingTop: '16px',
                            fontWeight: '900',
                        }}
                    />
                </>
            ) : (
                <Typography>{t(`${localization}.data_not_found`)}</Typography>
            )}
        </Grid>
    )
}

Body.propTypes = { data: PropTypes.any, localization: PropTypes.string }

export default Body
