import { Grid, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    useCancelIssueMutation,
    useContinueCurrentIssueMutation,
    useSupervisorCreateNewIssueMutation,
    useSupervisorMakeDeviceIsTotalLossMutation,
} from '~/pages/Issue/mutation'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useNavigate } from 'react-router-dom'
import { ISSUE_ISSUE_DETAIL } from '~/constants/Routes'
import { useSupervisorAcceptQCMutation } from '~/pages/Issue/QualityCheck/mutation'
import { useMemo, useState, useEffect } from 'react'
import SectionTitle from '~/pages/Diagnostic/components/SectionTitle'
import { useIssueQualityCheckQuery } from '../../QualityCheck/query'
import {
    ASSIGNED_TO_SUPERVISOR,
    ATTENTION_NEEDED,
} from '~/pages/Issue/Overview/constants'
import { parseApiErrorMessage, parseErrorMessageAndShow } from '~/utils/helpers'

export const PopupSupervisorProcessIssue = ({
    isOpened,
    closeHandler,
    issueId,
    issueData,
}) => {
    const localization = 'pages.issues.overview.'
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    const navigate = useNavigate()

    const [isOpenCommentBox, setIsOpenCommentBox] = useState(false)
    const [comment, setComment] = useState('')

    const isAssignedToSupervisor = useMemo(() => {
        return issueData?.state === ASSIGNED_TO_SUPERVISOR
    }, [issueData?.state])

    const enableAcceptQc = useMemo(() => {
        return issueData?.status === ATTENTION_NEEDED
    }, [issueData?.status])

    const {
        data,
        isLoading: isFetchingQc,
        isFetched: isFetchedQC,
    } = useIssueQualityCheckQuery(
        issueId,
        enableAcceptQc,
        isAssignedToSupervisor,
    )

    const { mutate: createANewIssue, isLoading: isCreating } =
        useSupervisorCreateNewIssueMutation()

    const { mutate: acceptQC, isLoading: isAccepting } =
        useSupervisorAcceptQCMutation()

    const { mutate: makeDeviceIsLoss, isLoading: isMaking } =
        useSupervisorMakeDeviceIsTotalLossMutation()

    const { mutate: continueCurrentIssue, isLoading: isContinueIssue } =
        useContinueCurrentIssueMutation()

    const isLoading = useMemo(() => {
        return (
            isCreating ||
            isAccepting ||
            isMaking ||
            isFetchingQc ||
            isContinueIssue
        )
    }, [isAccepting, isCreating, isMaking, isFetchingQc, isContinueIssue])

    useEffect(() => {
        if (isFetchedQC) {
            setComment(data?.data?.comment)
        }
    }, [isFetchedQC, data?.data])

    const createANewIssueHandler = () => {
        createANewIssue(
            { issueId },
            {
                onSuccess: ({ issue_id }) => {
                    queryClient.invalidateQueries(['issue_logs'])
                    queryClient.invalidateQueries('issues')
                    openSnackbar({
                        message: t(
                            localization + 'create_new_issue_successfully',
                        ),
                        type: 'success',
                    })
                    navigate({
                        pathname: ISSUE_ISSUE_DETAIL.replace(
                            ':issueId',
                            issue_id,
                        ),
                    })
                    closeHandler()
                },
                onError: (error) => {
                    const errorCode = error.response?.data?.error_code
                    const type =
                        errorCode === 'SWITCH_TEAM' ||
                        errorCode === 'CONTACT_SUPERVISOR'
                            ? 'warning'
                            : 'error'
                    parseErrorMessageAndShow(error, openSnackbar, type)
                },
            },
        )
    }

    const acceptQCHandler = () => {
        acceptQC(
            { issueId, comment },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['issue_logs'])
                    queryClient.invalidateQueries(['issue', issueId])
                    queryClient.invalidateQueries([
                        'issue_quality_check',
                        issueId,
                    ])
                    openSnackbar({
                        message: t(localization + 'accept_qc_successfully'),
                        type: 'success',
                    })
                    handleCloseComment()
                    closeHandler()
                },
                onError: (error) => {
                    const errorCode = error.response?.data?.error_code
                    const type =
                        errorCode === 'SWITCH_TEAM' ||
                        errorCode === 'CONTACT_SUPERVISOR'
                            ? 'warning'
                            : 'error'
                    parseErrorMessageAndShow(error, openSnackbar, type)
                },
            },
        )
    }

    const makeDeviceIsLossHandler = () => {
        makeDeviceIsLoss(
            { issueId },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['issue_logs'])
                    queryClient.invalidateQueries(['issue', issueId])
                    openSnackbar({
                        message: t(
                            localization + 'make_device_is_loss_successfully',
                        ),
                        type: 'success',
                    })
                    closeHandler()
                },
                onError: (error) => {
                    const errorCode = error.response?.data?.error_code
                    const type =
                        errorCode === 'SWITCH_TEAM' ||
                        errorCode === 'CONTACT_SUPERVISOR'
                            ? 'warning'
                            : 'error'
                    parseErrorMessageAndShow(error, openSnackbar, type)
                },
            },
        )
    }

    const continueCurrentIssueHandler = () => {
        continueCurrentIssue(
            { issueId },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['issue_logs'])
                    queryClient.invalidateQueries(['issue', issueId])
                    openSnackbar({
                        message: t(localization + 'reset_issue_successfully'),
                        type: 'success',
                    })
                    closeHandler()
                },
                onError: (error) => {
                    openSnackbar({
                        message: parseApiErrorMessage(error),
                        type: 'error',
                    })
                },
            },
        )
    }

    const handleCloseComment = () => {
        setIsOpenCommentBox(false)
    }

    const handleOpenComment = () => {
        setIsOpenCommentBox(true)
    }
    return (
        <>
            <StyledModal
                open={isOpened && issueId !== 0}
                handleClose={closeHandler}
                stackProps={{
                    width: 660,
                }}
            >
                <Typography variant='h6'>
                    {t(localization + 'has_expert_right_message')}
                </Typography>
                <Stack direction='row' gap={2} sx={{ marginTop: '5px' }}>
                    {issueData?.ticket?.flag_message}
                </Stack>
                <Stack direction='row' gap={2} sx={{ marginTop: '20px' }}>
                    <LoadingButton
                        label={t(localization + 'accept_qc')}
                        onClick={handleOpenComment}
                        loading={isLoading}
                        disabled={
                            !data || issueData?.status === 'ATTENTION_NEEDED'
                        }
                    />
                    <LoadingButton
                        label={t(localization + 'continue_current_issue')}
                        onClick={continueCurrentIssueHandler}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(localization + 'device_is_total_loss')}
                        onClick={makeDeviceIsLossHandler}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(localization + 'create_new_issue')}
                        onClick={createANewIssueHandler}
                        loading={isLoading}
                        disabled={issueData?.status === 'ATTENTION_NEEDED'}
                    />
                </Stack>
            </StyledModal>

            <StyledModal
                open={isOpenCommentBox && issueId !== 0}
                handleClose={handleCloseComment}
                stackProps={{
                    width: 660,
                    gap: 4,
                }}
            >
                <SectionTitle title={t(localization + 'comment')} />
                <TextField
                    multiline
                    maxRows={5}
                    minRows={5}
                    value={comment}
                    disabled={isFetchingQc || isAccepting}
                    onChange={(e) => {
                        const newComment = e.target.value

                        setComment(newComment)
                    }}
                    style={{ width: '100%' }}
                />
                <Stack direction='row' gap={2}>
                    <LoadingButton
                        label={t('button.close')}
                        onClick={handleCloseComment}
                        bgColor='gray'
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t('button.save')}
                        onClick={() => acceptQCHandler(comment)}
                        loading={isLoading}
                    />
                </Stack>
            </StyledModal>
        </>
    )
}

PopupSupervisorProcessIssue.propTypes = {
    isOpened: PropTypes.bool,
    closeHandler: PropTypes.func,
    issueId: PropTypes.string,
    issueData: PropTypes.object,
}

export const PopupCancelIssue = ({ isOpened, closeHandler, issueId }) => {
    const localization = 'pages.issues.create.'
    const { t } = useTranslation()
    const { mutate, isLoading } = useCancelIssueMutation(issueId)
    const handleCancelIssue = () => {
        mutate()
        closeHandler()
    }
    return (
        <StyledModal
            open={isOpened && issueId !== 0}
            handleClose={closeHandler}
        >
            <Grid>
                <Typography variant='h6' mt={2} mb={2}>
                    {t(localization + 'confirmCancel')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction='row'
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    display='flex'
                    spacing={2}
                >
                    <LoadingButton
                        onClick={closeHandler}
                        variant='contained'
                        size='large'
                        bgColor='gray'
                        label={t(localization + 'no')}
                    />
                    <LoadingButton
                        onClick={handleCancelIssue}
                        loading={isLoading}
                        variant='contained'
                        size='large'
                        label={t(localization + 'yes')}
                    />
                </Stack>
            </Grid>
        </StyledModal>
    )
}

PopupCancelIssue.propTypes = {
    isOpened: PropTypes.bool,
    closeHandler: PropTypes.func,
    issueId: PropTypes.string,
}
