import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ExternalLink from '~/components/ExternalLink'
import { ProcurementOrderHeadCells } from '~/components/HeaderTable/OrderConfirmationHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { COPACO_ORDER_NUMBER } from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { capitalize } from '~/utils/helpers'
import ProcurementOrderDetail from '../Detail'
import { useProcurementOrdersQuery } from '../query'
import ProcurementOrderListToolbar from './ProcurementOrderListToolbar'
import ProcurementOrderTableFilters from './ProcurementOrderTableFilters'

const localization = 'pages.procurement.procurementOrder.overview.'

const copacoStatuses = ['processed', 'delayed', 'rejected']
const notShowStatuses = ['processing', 'not received']

const ProcurementOrderBodyTable = ({
    isLoading,
    procurementOrder,
    setOpenProcurementModal,
    setOrderData,
}) => {
    const endUserInformation = useCallback((endUser) => {
        return (
            <>
                {endUser?.name && <Typography>{endUser?.name}</Typography>}
                {endUser?.country && (
                    <Typography>{`Country: ${endUser?.country}`}</Typography>
                )}
            </>
        )
    }, [])

    const getOrderNumber = useCallback((status, orderNumber) => {
        if (copacoStatuses.includes(status)) {
            return (
                <ExternalLink
                    extLink={COPACO_ORDER_NUMBER}
                    param={orderNumber}
                />
            )
        }

        if (notShowStatuses.includes(status)) {
            return null
        }

        return orderNumber
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={ProcurementOrderHeadCells.length} />
    }

    return (
        <TableBody>
            {procurementOrder.length > 0 ? (
                procurementOrder.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align='left'>
                                <Link
                                    component='button'
                                    to='#'
                                    onClick={() => {
                                        setOpenProcurementModal(true)
                                        setOrderData(row)
                                    }}
                                >
                                    {row.id}
                                </Link>
                            </TableCell>
                            <TableCell align='left'>
                                {row.supplier_name}
                            </TableCell>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'purchase-order-id'}
                                dataIdentifier={row.customer_order_number}
                                dataDisplay={row.customer_order_number}
                            />

                            <TableCell align='left'>
                                {getOrderNumber(row.status, row.order_number)}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.order_date}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                {capitalize(row.status)}
                            </TableCell>
                            <TableCell align='left'>
                                {row.complete_delivery}
                            </TableCell>
                            <TableCell align='left'>
                                {endUserInformation(row.end_user_information)}
                            </TableCell>
                            <TableCell align='left'>{row.to_city}</TableCell>
                            <TableCell align='left'>
                                {row.amount_excl_vat}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ProcurementOrderHeadCells.length} />
            )}
        </TableBody>
    )
}

ProcurementOrderBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    procurementOrder: PropTypes.arrayOf(PropTypes.object),
    setOpenProcurementModal: PropTypes.func,
    setOrderData: PropTypes.func,
}

const ProcurementOrderTable = () => {
    const [supplier, setSupplier] = useState('')
    const [status, setStatus] = useState('')
    const [openProcurementModal, setOpenProcurementModal] = useState(false)
    const [orderData, setOrderData] = useState(null)

    const handleClose = () => {
        setOpenProcurementModal(false)
    }

    const {
        results: procurementOrder,
        data,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useProcurementOrdersQuery,
        queryAttributes: {
            supplier,
            status,
        },
    })

    const refValue = useRef({
        supplier: '',
        status: '',
    })

    useEffect(() => {
        if (
            supplier !== refValue.current.supplier ||
            status !== refValue.current.status
        ) {
            setPage(1)
            refValue.current.supplier = supplier
            refValue.current.status = status
        }
    }, [setPage, status, supplier])

    return (
        <>
            <ProcurementOrderListToolbar
                payload={{ supplier, status, search, order, orderBy }}
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <ProcurementOrderTableFilters
                    {...{
                        search,
                        searchHandler,
                        supplier,
                        setSupplier,
                        status,
                        setStatus,
                        onEnterSearch,
                    }}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={ProcurementOrderHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <ProcurementOrderBodyTable
                            isLoading={isLoading}
                            procurementOrder={procurementOrder}
                            setOpenProcurementModal={setOpenProcurementModal}
                            setOrderData={setOrderData}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
            <ProcurementOrderDetail
                open={openProcurementModal}
                handleClose={handleClose}
                rowData={orderData}
            />
        </>
    )
}
export default ProcurementOrderTable
