export const SUPER_ADMIN = 'super_admin'

// Dashboard
export const PERMISSION_DASHBOARD = 'dashboard'

// Issue
export const PERMISSION_ISSUE_OVERVIEW = 'issues.overview'
export const PERMISSION_ISSUE_TICKETS = 'issues.tickets'
export const PERMISSION_ISSUE_EXPERT = 'issues.expert'
export const PERMISSION_ISSUE_PART_DELIVERED = 'issues.parts_delivered'

export const PERMISSION_ISSUE_QC_OVERRULE = 'issues.quality_checks.overrule'

export const PERMISSION_ISSUE_DIAGNOSTICS_ACCIDENTAL_DAMAGE =
    'issues.diagnostics.accidental_damage'
export const PERMISSION_ISSUE_DIAGNOSTICS_MALICIOUS_DAMAGE =
    'issues.diagnostics.malicious_damage'
export const PERMISSION_ISSUE_DIAGNOSTICS_ADD_ADDITIONAL_PARTS =
    'issues.diagnose.add_additional_parts'

export const PERMISSION_ISSUE_DISPATCH_VIEW = 'issues.dispatches.view'
export const PERMISSION_ISSUE_DISPATCH_CREATE = 'issues.dispatches.create'
export const PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT =
    'issues.dispatches.import_export'

export const PERMISSION_ISSUE_BOM_OVERVIEW = 'issues.bom.overview'
export const PERMISSION_ISSUE_BOM_EDIT = 'issues.bom.edit'
export const PERMISSION_ISSUE_BOM_EDIT_STOCK_LABEL =
    'issues.bom.edit.stock_label'

export const PERMISSION_ISSUE_ARCHIVE = 'issues.archive'
export const PERMISSION_ISSUE_ARCHIVE_IMAGES = 'issues.archive.images'

export const PERMISSION_ISSUE_RACKSCANS = 'issues.rackscans'
export const PERMISSION_ISSUE_RACKSCANS_TICKETS = 'issues.rack_scan.ticket'
export const PERMISSION_ISSUE_RACKSCANS_SCAN = 'issues.rack_scan.scan'

export const PERMISSION_ISSUE_CLOSE = 'issues.close'

export const PERMISSION_ISSUE_IMAGES_CANCEL = 'issues.images.cancel'
export const PERMISSION_ISSUE_IMAGES_PROCEED = 'issues.images.proceed'

// Fleet
export const PERMISSION_FLEET_OVERVIEW = 'fleet.overview'
export const PERMISSION_FLEET_OVERVIEW_VENDOR = 'fleet.overview.vendor'
export const PERMISSION_FLEET_OVERVIEW_WINDOW = 'fleet.overview.windows'
export const PERMISSION_FLEET_OVERVIEW_CHROME = 'fleet.overview.chrome_books'

export const PERMISSION_FLEET_SERIAL = 'fleet.serials'
export const PERMISSION_FLEET_SERIAL_ADD = 'fleet.serials.addSerial'
export const PERMISSION_FLEET_SERIAL_UPDATE = 'fleet.serials.update'

export const PERMISSION_FLEET_IMPORT_DEVICES = 'fleet.import_devices'
export const PERMISSION_FLEET_MODEL = 'fleet.models'
export const PERMISSION_FLEET_SWAP = 'fleet.swaps'

// Parts
export const PERMISSION_PART = 'parts'
export const PERMISSION_PART_COMPONENT = 'parts.components'
export const PERMISSION_PART_PARTCODE = 'parts.partcodes'

export const PERMISSION_PART_PRODUCT = 'parts.products'
export const PERMISSION_PART_PRODUCT_LINK_COMPONENTS =
    'parts.products.link_component'

// Knowledge
export const PERMISSION_KNOWLEDGE = 'knowledge'
export const PERMISSION_KNOWLEDGE_OVERVIEW = 'knowledge.overview'

// Claims
export const PERMISSION_CLAIM_RENUMERATION = 'claims'
export const PERMISSION_CLAIM_RENUMERATION_CHECK = 'claims.renumeration_check'
export const PERMISSION_CLAIM_INVOICE = 'claims.invoice'
export const PERMISSION_CLAIM_DISPUTE = 'claims.disputes'
export const PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE =
    'claims.disputes.import_remittance'

// Procurement
export const PERMISSION_PROCUREMENT = 'procurement'
export const PERMISSION_PROCUREMENT_PURCHASEORDER = 'procurement.purchase_order'
export const PERMISSION_PROCUREMENT_ORDERCONFIRMATION =
    'procurement.order_confirmation'

export const PERMISSION_PROCUREMENT_ORDER = 'procurement.order'
export const PERMISSION_PROCUREMENT_INVOICE = 'procurement.invoices'
export const PERMISSION_PROCUREMENT_DELIVERY = 'procurement.deliveries'
export const PERMISSION_PROCUREMENT_RMA = 'procurement.rma'

// Production
export const PERMISSION_PRODUCTION = 'production'

// Administrator
export const PERMISSION_ADMINISTRATOR = 'administrator'

export const ADMINISTRATOR_USER_IMPERSONATE = 'administrator.users.impersonate'

export const PERMISSION_ADMINISTRATOR_USER = 'administrator.users'
export const PERMISSION_ADMINISTRATOR_USER_DISABLED =
    'administrator.users.disabled_users'

export const PERMISSION_ADMINISTRATOR_ROLE = 'administrator.roles'

export const PERMISSION_ADMINISTRATOR_SYSTEM = 'administrator.system'
export const PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG =
    'administrator.system.error_log'
export const PERMISSION_ADMINISTRATOR_SYSTEM_API = 'administrator.system.api'
export const PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT =
    'administrator.system.service_account'

export const PERMISSION_ADMINISTRATOR_TOOLTIPS =
    'administrator.definitions.tooltips'

export const PERMISSION_ADMINISTRATOR_DATASCHEDULER =
    'administrator.data_schedulers'
export const PERMISSION_ADMINISTRATOR_DATASCHEDULER_VIEW =
    'administrator.data_schedulers.view'
export const PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE =
    'administrator.data_schedulers.create'
export const PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT =
    'administrator.data_schedulers.update'

export const PERMISSION_ADMINISTRATOR_DEFINITION = 'administrator.definitions'
export const PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE =
    'administrator.definitions.causes'
export const PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE =
    'administrator.definitions.causes.create'

export const PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER =
    'administrator.definitions.service_center'
export const PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH =
    'administrator.workbenches'
export const PERMISSION_ADMINISTRATOR_DEFINITION_ADDRESSES_CREATE =
    'administrator.definitions.addresses.create'

export const PERMISSION_ADMINISTRATOR_TEAM = 'administrator.team'
export const PERMISSION_ADMINISTRATOR_NAMING_TAG =
    'administrator.definitions.naming_rules'

export const PERMISSION_ADMINISTRATOR_TEST_DATA = 'test_data.view'

export const PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS =
    'administrator.production'
export const PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS = 'production.addons'
