import { OpenInNew } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import CustomToggleButton from '~/components/Button/CustomToggleButton'
import LoopIcon from '~/components/Button/LoopIcon'
import CustomCardHeader from '~/components/CustomCardHeader'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import ReferencedEntities from '~/components/ReferencedEntities'
import { StyledPaper } from '~/components/StyledPaper'
import { SwitchTeamButton } from '~/components/SwitchTeamButton'
import usePermissions from '~/hooks/usePermission'
import {
    PopupCancelIssue,
    PopupSupervisorProcessIssue,
} from '~/pages/Issue/Overview/components/Popup'
import {
    ASSIGNED_TO_SUPERVISOR,
    CANCELLED,
    CLOSED,
    REJECTED,
} from '~/pages/Issue/Overview/constants'
import { selectUser } from '~/store/auth/selector'
import { selectCanProceed } from '~/store/issue/selector'
import { toggleCanProceed } from '~/store/issue/slice'
import { ticketExternalLink } from '~/utils/helpers'
import DeviceHistory from '../Diagnostic/DeviceHistory'
import IssueLog from '../Diagnostic/IssueLog'
import { useIssueQuery } from './query'

export const Layout = ({ children, breadcrumbs, pageName }) => {
    return (
        <>
            <BreadCrumb {...{ breadcrumbs }} />
            <PageName name={pageName} />
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.any,
    breadcrumbs: PropTypes.array,
    pageName: PropTypes.string,
}

export const IssueDetailBody = () => {
    const { issueId } = useParams()
    const localization = 'pages.issues.create'
    const { t } = useTranslation('translation', { keyPrefix: localization })
    const [showProceedIssuePopup, setShowProceedIssuePopup] = useState(false)
    const [showCancelPopup, setShowCancelPopup] = useState(false)
    const [refetchInterval, setRefectchInterval] = useState(0)
    const [fetching, setFetching] = useState(
        localStorage.getItem('IS_FETCHING_ISSUE') === 'true',
    )

    const {
        data,
        isLoading: isQueryLoading,
        isSuccess,
        refetch,
    } = useIssueQuery(issueId, refetchInterval)
    const { permissions } = usePermissions()
    const user = useSelector(selectUser)
    const canProceed = useSelector(selectCanProceed)
    const dispatch = useDispatch()

    const initialValues = useMemo(() => {
        if (isSuccess) {
            return { ...data.data, serial: data.data.name }
        }
        return {}
    }, [data, isSuccess])

    const canManageExpertIssue = permissions.issue.canManageExpertIssue
    const canCloseIssuePermission = permissions.issue.canCloseIssue

    const isShownCancelButton = useMemo(
        () =>
            !!(
                canCloseIssuePermission &&
                ![CANCELLED, REJECTED, CLOSED].includes(initialValues?.state)
            ),
        [initialValues, canCloseIssuePermission],
    )

    const isShownProceedButton = useMemo(
        () =>
            !!(
                canManageExpertIssue &&
                initialValues?.state === ASSIGNED_TO_SUPERVISOR
            ),
        [initialValues, canManageExpertIssue],
    )

    const isShowSwitchTeamButton = useMemo(() => {
        if (
            user?.current_team?.service_center?.id ===
            initialValues?.service_center?.id
        ) {
            dispatch(toggleCanProceed(true))
            return false
        }
        return !!(initialValues?.service_center?.id && isShownProceedButton)
    }, [
        dispatch,
        initialValues?.service_center?.id,
        isShownProceedButton,
        user?.current_team?.service_center?.id,
    ])

    useEffect(() => {
        if (fetching) {
            refetch()
            setRefectchInterval(30000)
        } else {
            setRefectchInterval(0)
        }
    }, [fetching, refetch])

    if (isQueryLoading) {
        return <Loading />
    }

    return (
        <Grid container spacing={2}>
            <Stack direction='column' spacing={3} sx={{ width: '100%' }}>
                <Card>
                    <CustomCardHeader
                        title={`Issue ID: ${initialValues?.id}`}
                        action={
                            <Box
                                direction='row'
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                            >
                                <CustomToggleButton
                                    content={<LoopIcon isFetching={fetching} />}
                                    value='check'
                                    selected={fetching}
                                    onChange={(e) => {
                                        localStorage.setItem(
                                            'IS_FETCHING_ISSUE',
                                            !fetching,
                                        )
                                        setFetching((v) => !v)
                                    }}
                                />
                                {isShowSwitchTeamButton && (
                                    <SwitchTeamButton
                                        serviceCenter={
                                            initialValues.service_center
                                        }
                                        user={user}
                                    />
                                )}
                                {isShownProceedButton && (
                                    <Button
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#76B72A',
                                        }}
                                        onClick={() => {
                                            setShowProceedIssuePopup(true)
                                        }}
                                        disabled={!canProceed}
                                    >
                                        {t('process_supervisor_issue')}
                                    </Button>
                                )}
                                {isShownCancelButton && (
                                    <Button
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#102F44',
                                        }}
                                        onClick={() => setShowCancelPopup(true)}
                                    >
                                        {t('cancelIssue')}
                                    </Button>
                                )}
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`ticket_id`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.ticket_id ? (
                                        <Link
                                            target='_blank'
                                            underline='none'
                                            href={ticketExternalLink(
                                                initialValues?.ticket_id,
                                                data?.url,
                                            )}
                                        >
                                            {initialValues?.ticket_id}
                                            <IconButton
                                                size='small'
                                                sx={{
                                                    padding: 0,
                                                    marginLeft: '3px',
                                                    marginBottom: '3px',
                                                }}
                                            >
                                                <OpenInNew />
                                            </IconButton>
                                        </Link>
                                    ) : (
                                        'NULL'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t('service_center')}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.service_center?.name ??
                                        'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`esd_location`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.esd_location ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`serial`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.serial ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`description`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.description ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`status`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.status ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`state`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.state ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`bom_state`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.bom_state ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`reject_reason`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.reject_reason ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`created_at`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.created_at ? (
                                        <DateTimeLocale
                                            datetime={initialValues?.created_at}
                                        />
                                    ) : (
                                        'NULL'
                                    )}
                                </Typography>
                            </Grid>
                            {[
                                'service_id',
                                'brand',
                                'school_id',
                                'device_user_id',
                                'contract_id',
                                'damage_description',
                            ].map((fieldName, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t(fieldName)}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {initialValues?.ticket?.[fieldName] ??
                                            'NULL'}
                                    </Typography>
                                </Grid>
                            ))}
                            {['service_center_tid'].map((fieldName, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t(fieldName)}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {initialValues?.service_center?.[
                                            fieldName
                                        ] ?? 'NULL'}
                                    </Typography>
                                </Grid>
                            ))}
                            {['damaged', 'powers_on', 'liquid_damage'].map(
                                (fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            {initialValues?.[fieldName]
                                                ? 'True'
                                                : 'False'}
                                        </Typography>
                                    </Grid>
                                ),
                            )}
                            {[
                                'cause_tid',
                                'accessories',
                                'loaner_id',
                                'webshop_id',
                                'image_version_tid',
                                'language',
                                'entity_tid',
                            ].map((fieldName, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t(fieldName)}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {initialValues?.ticket?.[fieldName] ??
                                            'NULL'}
                                    </Typography>
                                </Grid>
                            ))}
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`previous_id`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.previous_id ?? 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`dispatch_status`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.latest_dispatch?.status ??
                                        'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`dispatch_id`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.latest_dispatch?.id ? (
                                        <ReferencedEntities
                                            isTableCell={false}
                                            dataType={'dispatch-id'}
                                            dataIdentifier={
                                                initialValues.latest_dispatch.id
                                            }
                                        />
                                    ) : (
                                        'N/A'
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Grid item xs={12}>
                        <StyledPaper>
                            <IssueLog />
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledPaper>
                            <DeviceHistory />
                        </StyledPaper>
                    </Grid>
                </Card>
            </Stack>
            <PopupSupervisorProcessIssue
                isOpened={showProceedIssuePopup}
                closeHandler={() => {
                    setShowProceedIssuePopup(false)
                }}
                issueId={issueId}
                issueData={data?.data}
            />
            <PopupCancelIssue
                isOpened={showCancelPopup}
                closeHandler={() => setShowCancelPopup(false)}
                issueId={issueId}
            />
        </Grid>
    )
}
