import Checkbox from '@mui/material/Checkbox'
import { Grid, Typography } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import Proptypes from 'prop-types'

const CheckboxComponent = ({ input, ...props }) => {
    return <Checkbox {...input} {...props} />
}

CheckboxComponent.propTypes = {
    input: Proptypes.any,
}

const FinalFormField = ({ name, checked, ...props }) => {
    return (
        <Field
            {...props}
            name={`${name}.value`}
            component={CheckboxComponent}
            type='checkbox'
            id={name}
        />
    )
}

FinalFormField.propTypes = {
    name: Proptypes.string,
    checked: Proptypes.bool,
}

const CheckboxesField = ({
    name: fieldName,
    localization,
    label = true,
    styledForLabel,
    ...props
}) => {
    const { t } = useTranslation()

    const customElementLabel = (FieldArray) => {
        if (FieldArray.name && FieldArray.tid) {
            return `${FieldArray.name} (${FieldArray.tid})`
        }
        return FieldArray.name
    }

    return (
        <>
            {label && (
                <Typography sx={styledForLabel}>
                    {t(`${localization}.${fieldName}`)}
                </Typography>
            )}

            <FieldArray name={fieldName}>
                {(fieldArrayProps) => {
                    const fieldArrayValue = fieldArrayProps.fields.value
                    return fieldArrayProps.fields.map((name, index) => {
                        return (
                            <Grid item xs={12} md={6} key={name}>
                                <FormControlLabel
                                    control={
                                        <FinalFormField
                                            name={name}
                                            checked={
                                                fieldArrayValue[index].value
                                            }
                                            {...props}
                                        />
                                    }
                                    label={customElementLabel(
                                        fieldArrayValue[index],
                                    )}
                                />
                            </Grid>
                        )
                    })
                }}
            </FieldArray>
        </>
    )
}

CheckboxesField.propTypes = {
    name: Proptypes.string,
    localization: Proptypes.string,
    label: Proptypes.bool,
    styledForLabel: Proptypes.object,
}

export default CheckboxesField
