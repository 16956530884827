import { Box, Button, Grid, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchToolBar from '~/components/SearchToolBar'
import DropDownTable from './Table/DropDownTable'
import MultiFilterTable from './Table/MultiFilterTable'
import { selectFilterStateObject } from '~/store/filter/selector'
import { setFilterObject } from '~/store/filter/slice'
import { makeStyles } from 'tss-react/mui'
import RangeDateFieldPicker from './RangeDatePicker/RangeDateFieldPicker'

const useStyles = makeStyles()((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '10px',
    },
}))
const TableFilters = ({
    selectGroup = [],
    search,
    searchHandler,
    searchPlaceHolder,
    onEnterSearch,
    page = '',
    selectMultiple = false,
    showDatePicker = false,
    rangeDateGroup = [],
}) => {
    const { classes } = useStyles()
    const filterRedux = useSelector(selectFilterStateObject)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const p = 1

    useEffect(() => {
        if (page !== filterRedux.page) {
            return
        }

        selectGroup.forEach((element) => {
            if (element.value === '') {
                const found = filterRedux.filter.find(
                    (filter) => filter.name === element.label,
                )
                if (found) {
                    element.handleFunc(found.value)
                }
            }
        })

        rangeDateGroup.forEach((element) => {
            if (element.value === null) {
                const found = filterRedux.filter.find(
                    (filter) => filter.name === element.label,
                )
                if (found) {
                    element.handleFunc(found.value)
                }
            }
        })
    }, [
        rangeDateGroup,
        filterRedux.filter,
        filterRedux.page,
        page,
        selectGroup,
    ])

    const handleClearFilter = () => {
        selectGroup.forEach((element) => {
            dispatch(
                setFilterObject({
                    p,
                    filter: {
                        name: element.label,
                        value: element?.multiple === true ? ['All'] : 'All',
                    },
                }),
            )
            element.handleFunc(element?.multiple === true ? [] : '')
        })
        rangeDateGroup.forEach((el) => {
            dispatch(
                setFilterObject({
                    p,
                    filter: {
                        name: el.label,
                        value: [null, null],
                    },
                }),
            )
            el.handleFunc([null, null])
        })
    }
    return (
        <Toolbar>
            <Grid container spacing={0.5}>
                {selectGroup.map((item, index) =>
                    selectMultiple ? (
                        <MultiFilterTable
                            filterObject={item}
                            key={index}
                            page={page}
                        />
                    ) : (
                        <DropDownTable
                            filterObject={item}
                            key={index}
                            page={page}
                        />
                    ),
                )}

                {!(showDatePicker && rangeDateGroup.length === 0) && (
                    <>
                        {rangeDateGroup.map((date, index) => (
                            <Grid
                                item
                                className={classes.container}
                                key={index}
                            >
                                <RangeDateFieldPicker
                                    filterObject={date}
                                    page={page}
                                />
                            </Grid>
                        ))}
                    </>
                )}

                {selectGroup?.length > 0 && (
                    <Button
                        variant='contained'
                        size='small'
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#76B72A',
                            height: '55px',
                            ml: '15px',
                            mt: '12px',
                        }}
                        type={'button'}
                        onClick={() => {
                            handleClearFilter()
                        }}
                    >
                        {t('general.clearFilter')}
                    </Button>
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Grid item xs={12} sm={2}>
                    <SearchToolBar
                        filterSearch={search}
                        onFilterSearch={searchHandler}
                        placeholder={searchPlaceHolder}
                        onEnterSearch={onEnterSearch}
                    />
                </Grid>
            </Grid>
        </Toolbar>
    )
}

TableFilters.propTypes = {
    selectGroup: PropTypes.array,
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    searchPlaceHolder: PropTypes.string,
    onEnterSearch: PropTypes.func,
    page: PropTypes.string,
    selectMultiple: PropTypes.bool,
    showDatePicker: PropTypes.bool,
    rangeDateGroup: PropTypes.array,
}

TableFilters.defaultProps = {
    selectMultiple: false,
    showDatePicker: false,
}

export default TableFilters
