import { Button, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { IMPERSONATE_TOKEN } from '~/constants/constants'
import { selectUser } from '~/store/auth/selector'
import ReportIcon from '@mui/icons-material/Report'
import { useTranslation } from 'react-i18next'
import { getImpersonateToken } from '~/utils/helpers'

const ImpersonateUser = () => {
    const { t } = useTranslation()
    const user = useSelector(selectUser)

    if (!getImpersonateToken()) {
        return null
    }

    return (
        <>
            <Tooltip
                placement='bottom'
                arrow
                title={`${t(`general.impersonatingUser`)}${user.name}`}
            >
                <Button
                    variant='contained'
                    size='small'
                    startIcon={<ReportIcon />}
                    onClick={() => {
                        localStorage.removeItem(IMPERSONATE_TOKEN)
                        window.location.reload()
                    }}
                    sx={{
                        textTransform: 'none',
                        backgroundColor: '#FF0000',
                        '&:hover': {
                            backgroundColor: '#102F44',
                            color: 'white',
                        },
                    }}
                >
                    {t(`general.deactiveImpersonation`)}
                </Button>
            </Tooltip>
        </>
    )
}

export default ImpersonateUser
