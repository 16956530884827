import PropTypes from 'prop-types'
import {
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar as MuiToolbar,
    Typography,
} from '@mui/material'
import { LoadingButton } from '~/components/Button'
import CancelButton, { CloseButton } from '~/components/CancelButton'
import { ADMINISTRATOR_DATASCHEDULER } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ToolBarButton from './ToolBarButton'

const ChangeStatusButton = ({
    currentStatus,
    changeExportSchedulerStatus,
    localization,
}) => {
    const { id } = useParams()
    const { t } = useTranslation()
    return (
        <ToolBarButton
            color={currentStatus ? 'error' : 'success'}
            handleAction={() =>
                changeExportSchedulerStatus({
                    id,
                    enabled: !currentStatus,
                })
            }
            name={t(`${localization}.${currentStatus ? 'disable' : 'enable'}`)}
        />
    )
}
ChangeStatusButton.propTypes = {
    currentStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    changeExportSchedulerStatus: PropTypes.func,
    localization: PropTypes.string,
}

const ToolbarForm = ({
    localization,
    isLoading,
    exportScheduler,
    changeExportSchedulerStatus,
    havePermissionToEdit = true,
}) => {
    const { t } = useTranslation()
    return (
        <MuiToolbar disableGutters={false}>
            <Box sx={{ flexGrow: 1 }}>
                {exportScheduler && !exportScheduler?.enable && (
                    <ListItem>
                        <ListItemIcon>
                            <Box
                                component='span'
                                className='material-symbols-rounded'
                                sx={{ color: '#FF0000' }}
                            >
                                info
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant='h6'
                                    sx={{ color: '#FF0000' }}
                                >
                                    {t(`message.disableSchedule`)}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
            </Box>
            <Stack direction='row-reverse' spacing={2}>
                {havePermissionToEdit && (
                    <LoadingButton
                        label={t(`${localization}.save`)}
                        type={`submit`}
                        loading={isLoading}
                        disabled={exportScheduler && !exportScheduler?.enable}
                    />
                )}
                {exportScheduler ? (
                    <>
                        {havePermissionToEdit && (
                            <ChangeStatusButton
                                currentStatus={exportScheduler?.enable}
                                changeExportSchedulerStatus={
                                    changeExportSchedulerStatus
                                }
                                localization={localization}
                            />
                        )}
                        <CloseButton link={ADMINISTRATOR_DATASCHEDULER} />
                    </>
                ) : (
                    <CancelButton />
                )}
            </Stack>
        </MuiToolbar>
    )
}
ToolbarForm.propTypes = {
    localization: PropTypes.string,
    isLoading: PropTypes.bool,
    exportScheduler: PropTypes.object,
    changeExportSchedulerStatus: PropTypes.func,
    havePermissionToEdit: PropTypes.bool,
}

export default ToolbarForm
