export const OrderConfirmationHeadCells = [
    {
        id: 'suppliers.name',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'customer_order_number',
        numeric: false,
        disablePadding: false,
        label: 'ourPO',
        orderable: true,
    },
    {
        id: 'order_number',
        numeric: false,
        disablePadding: false,
        label: 'supplierOrder',
        orderable: true,
    },
    {
        id: 'order_date',
        numeric: false,
        disablePadding: false,
        label: 'orderDate',
        orderable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'complete_delivery',
        numeric: false,
        disablePadding: false,
        label: 'deliveryStatus',
        orderable: true,
    },
    {
        id: 'response_code',
        numeric: false,
        disablePadding: false,
        label: 'responseCode',
        orderable: true,
    },
    {
        id: 'end_user_information',
        numeric: false,
        disablePadding: false,
        label: 'information',
        orderable: true,
    },

    {
        id: 'to_city',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: true,
    },
    {
        id: 'amount_excl_vat',
        numeric: false,
        disablePadding: false,
        label: 'amountExclVat',
        orderable: true,
    },

    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'receivedAPI',
        orderable: true,
    },
]

export const ProcurementOrderHeadCells = [
    {
        id: 'order_id',
        numeric: true,
        disablePadding: false,
        label: 'orderId',
        orderable: true,
    },
    {
        id: 'suppliers.name',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'customer_order_number',
        numeric: false,
        disablePadding: false,
        label: 'ourPO',
        orderable: true,
    },
    {
        id: 'order_number',
        numeric: false,
        disablePadding: false,
        label: 'supplierOrder',
        orderable: true,
    },
    {
        id: 'order_date',
        numeric: false,
        disablePadding: false,
        label: 'orderDate',
        orderable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'delivery_status',
        numeric: false,
        disablePadding: false,
        label: 'deliveryStatus',
        orderable: true,
    },
    {
        id: 'end_user_information',
        numeric: false,
        disablePadding: false,
        label: 'information',
        orderable: true,
    },

    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: true,
    },
    {
        id: 'amount_excl_vat',
        numeric: false,
        disablePadding: false,
        label: 'amountExclVat',
        orderable: true,
    },

    {
        id: 'receivedAPI',
        numeric: false,
        disablePadding: false,
        label: 'receivedAPI',
        orderable: true,
    },
]

export const ProcurementOrderDetailHeadCells = [
    {
        id: 'customer_id',
        numeric: true,
        disablePadding: false,
        label: 'customerId',
        orderable: false,
    },
    {
        id: 'to_city',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: false,
    },
    {
        id: 'amount_excl_vat',
        numeric: false,
        disablePadding: false,
        label: 'amountExclVat',
        orderable: false,
    },
    {
        id: 'amount_incl_vat',
        numeric: false,
        disablePadding: false,
        label: 'amountInclVat',
        orderable: false,
    },
    {
        id: 'description_response_code',
        numeric: false,
        disablePadding: false,
        label: 'descriptionResponseCode',
        orderable: false,
    },
    {
        id: 'order_date',
        numeric: false,
        disablePadding: false,
        label: 'orderDate',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: false,
    },
]

export const ProcurementOrderUserInformationHeadCells = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'endUserName',
        orderable: false,
    },
    {
        id: 'street',
        numeric: false,
        disablePadding: false,
        label: 'endUserStreet',
        orderable: false,
    },
    {
        id: 'postalcode',
        numeric: false,
        disablePadding: false,
        label: 'endUserPostalCode',
        orderable: false,
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'endUserCity',
        orderable: false,
    },
    {
        id: 'country',
        numeric: false,
        disablePadding: false,
        label: 'endUserCountry',
        orderable: false,
    },
]

export const ProcurementOrderBuyerHeadCells = [
    {
        id: 'ZIP',
        numeric: true,
        disablePadding: false,
        label: 'ZIP',
        orderable: false,
    },
    {
        id: 'City',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: false,
    },
    {
        id: 'Name1',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'Street',
        numeric: false,
        disablePadding: false,
        label: 'Street',
        orderable: false,
    },
    {
        id: 'Country',
        numeric: false,
        disablePadding: false,
        label: 'Country',
        orderable: false,
    },
    {
        id: 'PartnerID',
        numeric: false,
        disablePadding: false,
        label: 'PartnerID',
        orderable: false,
    },
    {
        id: 'CustContact',
        numeric: false,
        disablePadding: false,
        label: 'CustContact',
        orderable: false,
    },
]

export const ProcurementOrderSuplierHeadCells = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'address_1',
        numeric: false,
        disablePadding: false,
        label: 'address',
        orderable: false,
    },
    {
        id: 'postcode',
        numeric: false,
        disablePadding: false,
        label: 'postcode',
        orderable: false,
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: false,
    },
    {
        id: 'pobox_postcode',
        numeric: false,
        disablePadding: false,
        label: 'poboxPostcode',
        orderable: false,
    },
    {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'phone',
        orderable: false,
    },
    {
        id: 'fax',
        numeric: false,
        disablePadding: false,
        label: 'fax',
        orderable: false,
    },
    {
        id: 'bank_account',
        numeric: false,
        disablePadding: false,
        label: 'bankAccount',
        orderable: false,
    },
    {
        id: 'vat_number',
        numeric: false,
        disablePadding: false,
        label: 'vatNumber',
        orderable: false,
    },
]

export const ProcurementOrderLineHeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        orderable: false,
    },
    {
        id: 'line_number',
        numeric: true,
        disablePadding: false,
        label: 'lineNumber',
        orderable: false,
    },
    {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: 'quantity',
        orderable: false,
    },
    {
        id: 'item_id',
        numeric: false,
        disablePadding: false,
        label: 'itemId',
        orderable: false,
    },
    {
        id: 'item_description',
        numeric: false,
        disablePadding: false,
        label: 'itemDescription',
        orderable: false,
    },
    {
        id: 'manufacturer_item_id',
        numeric: false,
        disablePadding: false,
        label: 'manufacturerItemId',
        orderable: false,
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'price',
        orderable: false,
    },
    {
        id: 'line_amount',
        numeric: false,
        disablePadding: false,
        label: 'lineAmount',
        orderable: false,
    },
    {
        id: 'quantity_remaining',
        numeric: false,
        disablePadding: false,
        label: 'quantityRemaining',
        orderable: false,
    },
]
