import axiosClient, { clientDownload } from '~/api/base'

class ProcurementOrderApi {
    getProcurementOrders = (params) => {
        return axiosClient.get('api/procurement/procurement-orders', {
            params,
        })
    }

    getProcurementOrderLines = (id, params) => {
        return axiosClient.get(`api/procurement/orders/${id}/order-lines`, {
            params,
        })
    }

    getSuppliers = () => {
        return axiosClient.get('api/procurement/suppliers/list')
    }

    getOrderStatuses = () => {
        return axiosClient.get('api/procurement/orders/statuses')
    }

    getOrdersExport = (payload) =>
        clientDownload.post(
            'api/procurement/order-confirmation/export',
            payload,
        )
}

export default new ProcurementOrderApi()
