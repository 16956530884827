import issueApi from '~/api/issueApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { ISSUE_OVERVIEW } from '~/constants/Routes'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import issueTriageApi from '~/api/issueTriageApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useIssueCreateMutation = () => {
    return useMutation(
        async (values) => await issueApi.createIssue(values),
        useBuildMutationOptions(),
    )
}
export const useIssueUpdateMutation = (id) => {
    return useMutation(
        async (values) => await issueApi.updateIssue(id, values),
        useBuildMutationOptions(),
    )
}

const useBuildMutationOptions = () => {
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    return {
        onSuccess: (data) => {
            openSnackbar({
                message: data.message ? data.message : 'Create successful!',
                type: 'success',
            })

            navigate(ISSUE_OVERVIEW)
            return data
        },
        onError: (error) => {
            let message = 'Create failed!'
            if (error.response.data.message) {
                message = error.response.data.message
            } else if (error.message) {
                message = error.message
            }
            openSnackbar({
                message,
                type: 'error',
                duration: 10000,
            })
        },
        retry: false,
    }
}

export const useIssueRepairScanSerialMutation = () => {
    return useMutation(async ({ issueId, serial }) => {
        const { data } = await issueApi.scanSerialForRepair(issueId, serial)

        return data
    })
}

export const useIssueScanSerialDashboardMutation = () => {
    return useMutation(async ({ serial }) => {
        const { data } = await issueApi.scanSerial(serial)

        return data
    })
}

export const useIssueRepairScanReceivedPart = () => {
    return useMutation(async ({ issueId, ...payload }) => {
        return await issueApi.scanReceivedPartsForRepair(issueId, payload)
    })
}

export const useIssueRepairSaveReceivedParts = () => {
    return useMutation(async ({ issueId, ...payload }) => {
        return await issueApi.saveReceivedPartsForRepair(issueId, payload)
    })
}

export const useTransitIssueState = () => {
    return useMutation(async ({ issueId, ...payload }) => {
        return await issueApi.transitIssueState(issueId, payload)
    })
}

export const useAdvanceRepairMutation = () => {
    return useMutation(async ({ issueId, step }) => {
        return await issueApi.scanAdvanceRepair(issueId, step)
    })
}

export const useSaveRepairTechnicianCommentMutation = () => {
    return useMutation(async ({ issueId, comment }) => {
        return await issueApi.saveRepairTechnicianComment(issueId, {
            comment,
        })
    })
}

export const useSaveRepairMutation = () => {
    const { openSnackbar } = useDialogContext()
    return useMutation(
        async ({ issueId, payload }) => {
            return await issueApi.saveRepair(issueId, {
                ...payload,
            })
        },
        {
            onError: (error) => {
                let message = ''
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                openSnackbar({
                    message,
                    type: 'error',
                })
            },
        },
    )
}

export const useCancelIssueMutation = (id) => {
    const { openSnackbar } = useDialogContext()
    const queryClient = useQueryClient()
    return useMutation(
        async () => {
            return await issueApi.cancelIssue(id)
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: data?.message ? data.message : 'Issue canceled!',
                    type: 'success',
                })
                queryClient.invalidateQueries(['issue', id])
            },
            onError: (error) => {
                let message = ''
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                openSnackbar({
                    message,
                    type: 'error',
                })
            },
        },
    )
}

export const useCreateANewIssueFromOldMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueApi.createANewFromOldIssue(issueId)
        return data
    })
}

export const useSupervisorCreateNewIssueMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueApi.supervisorCreateNewIssue(issueId)
        return data
    })
}

export const useCancelImageIssueMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueApi.cancelImageIssue(issueId)
        return data
    })
}

export const useSupervisorMakeDeviceIsTotalLossMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueApi.supervisorMakeDeviceIsTotalLoss(issueId)
        return data
    })
}

export const useTriageDoneIssueMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueTriageApi.triageDone(issueId)
        return data
    })
}

export const useProceedIncorrectImageIssueMutation = () => {
    return useMutation(async ({ issueId, defaultAgreeQc }) => {
        const { data } = await issueApi.proceedIncorrectImageIssue(
            issueId,
            defaultAgreeQc,
        )
        return data
    })
}

export const useTransitionToImagingMutation = (issueId) => {
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation({
        mutationKey: ['transition_to_imaging', issueId],
        mutationFn: async () => {
            const { data } = await issueApi.transitionToImaging(issueId)
            return data
        },
        onSettled: () => {
            queryClient.invalidateQueries(['issue', issueId])
            queryClient.invalidateQueries(['issue_logs'])
        },
        onError: (error) => {
            openSnackbar({
                message: parseApiErrorMessage(error),
                type: 'error',
            })
        },
    })
}

export const useContinueCurrentIssueMutation = () => {
    return useMutation(async ({ issueId }) => {
        const { data } = await issueApi.continueCurrentIssue(issueId)
        return data
    })
}
