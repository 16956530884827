import { Box, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import LoadingCircle from '~/components/LoadingCircle'
import { useRolesQuery } from './query'
import RoleDetailsPermissionTag from './PermissionsTag/RoleDetailsPermissionTag'
import RoleUser from './ConnectedUsersTag/RoleMemberAccordion'

const tabList = [
    {
        index: 0,
        name: 'permissions',
    },
    {
        index: 1,
        name: 'connectedUsers',
    },
]
const localization = 'pages.administrator.roleDetail'

const TabPanel = (props) => {
    const { children, value, index, isTabLoading, ...other } = props
    if (isTabLoading && value === index) {
        return (
            <Box sx={{ p: 3 }}>
                <LoadingCircle />
            </Box>
        )
    }
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`role-tabpanel-${index}`}
            aria-labelledby={`role-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    isTabLoading: PropTypes.bool,
}

const a11yProps = (index) => {
    return {
        id: `role-tab-${index}`,
        'aria-controls': `role-tabpanel-${index}`,
    }
}

const RoleDetailsTags = ({ setBreadCrumbName }) => {
    const [value, setValue] = useState(0)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const urlParams = useParams()

    const { data, isLoading } = useRolesQuery({ id: urlParams.id })

    const location = useLocation()
    const [isTabLoading, setIsTabLoading] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (!location.search) {
            searchParams.set('tag', '0')
            const newSearchString = searchParams.toString()
            navigate({
                pathname: window.location.pathname,
                search: newSearchString,
            })
        } else if (searchParams.has('tag')) {
            const tabIndex = parseInt(searchParams.get('tag'))

            if (tabIndex > 1 || tabIndex < 0 || isNaN(tabIndex)) {
                searchParams.set('tag', '0')
                const newSearchString = searchParams.toString()
                navigate({
                    pathname: window.location.pathname,
                    search: newSearchString,
                })
            }

            const obj = tabList.some((t) => t.index === tabIndex)
            if (obj) {
                setValue(tabIndex)
            } else {
                setValue(0)
            }
        }
    }, [location.search, navigate])

    useEffect(() => {
        setBreadCrumbName(data?.data?.name ?? '')
    }, [data?.data?.name, setBreadCrumbName, urlParams.id])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('tag', newValue)
        const newSearchString = searchParams.toString()
        navigate({
            pathname: window.location.pathname,
            search: newSearchString,
        })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onAnimationStart={() => setIsTabLoading(true)}
                    onAnimationEnd={() => setIsTabLoading(false)}
                    value={value}
                    onChange={handleChange}
                >
                    {tabList.map((tab) => (
                        <Tab
                            key={tab.index}
                            label={t(`${localization}.${tab.name}`)}
                            {...a11yProps(tab.index)}
                        />
                    ))}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} isTabLoading={isTabLoading}>
                <RoleDetailsPermissionTag
                    setBreadCrumbName={setBreadCrumbName}
                    data={data}
                    isLoading={isLoading}
                />
            </TabPanel>
            <TabPanel value={value} index={1} isTabLoading={isTabLoading}>
                <RoleUser localization={localization} />
            </TabPanel>
        </Box>
    )
}

RoleDetailsTags.propTypes = {
    setBreadCrumbName: PropTypes.func,
}
export default RoleDetailsTags
