import axiosClient, { clientDownload } from '~/api/base'

class OrderConfirmationApi {
    getOrderConfirmations = (params) => {
        return axiosClient.get('api/procurement/procurement-orders', {
            params,
        })
    }

    getSuppliers = () => {
        return axiosClient.get('api/procurement/suppliers/list')
    }

    getOrderStatuses = () => {
        return axiosClient.get('api/procurement/orders/statuses')
    }

    getOrdersExport = (payload) =>
        clientDownload.post(
            'api/procurement/order-confirmation/export',
            payload,
        )
}

export default new OrderConfirmationApi()
