import { ADMINISTRATOR, ADMINISTRATOR_ROLE } from '~/constants/Routes'
import { useState } from 'react'
import BreadCrumb from '~/components/BreadCrumb'
import PropTypes from 'prop-types'
import RoleDetailsTags from './RoleDetailsTags'

const RoleDetail = () => {
    const [name, setName] = useState('')

    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_ROLE,
            title: 'Roles',
        },
        {
            page: 'detail',
            title: name,
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <RoleDetailsTags setBreadCrumbName={setName} />
        </>
    )
}

RoleDetail.propTypes = { handleSubmit: PropTypes.func }

export default RoleDetail
