import {
    ADMINISTRATOR,
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DATASCHEDULER_NEW,
} from '~/constants/Routes'
import { useCreateDataSchedulerMutation } from '../mutation'
import { Form } from 'react-final-form'
import Body from '../Body'
import BreadCrumb from '~/components/BreadCrumb'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import ToolbarForm from '~/components/ToolbarForm'
import { object, string } from 'yup'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { cron, multipleEmail, selectStatement } from '~/constants/yupSchemas'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useDispatch, useSelector } from 'react-redux'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useEffect, useMemo } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'
import ComponentTooltip from '~/components/ComponentTooltip'
import usePermissions from '~/hooks/usePermission'

const DataSchedulerCreate = () => {
    const localization = 'pages.administrator.dataScheduler.create'
    const { permissions } = usePermissions()
    const snackbarData = useSelector(selectSnackbar)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { showSnackbar, snackbar } = useSnackbar()

    const disabledField = useMemo(
        () => !permissions.administrator.dataScheduler.canCreate,
        [permissions.administrator.dataScheduler.canCreate],
    )

    const { mutate: createDataScheduler, isLoading } =
        useCreateDataSchedulerMutation({})

    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    }, [dispatch, showSnackbar, snackbarData])

    const schema = object().shape({
        name: string().required(),
        database: string().required(),
        statement: selectStatement(t).required(),
        method: string().required(),
        process: string().required(),
        emails: multipleEmail(t)
            .nullable()
            .when('method', {
                is: 'emailAttachment',
                then: string().required('Must enter email address'),
            }),
        cron: cron(t)
            .nullable()
            .when('process', {
                is: 'schedule',
                then: string().required('Must enter cron pattern'),
            }),
    })

    const validate = useFormValidationCallback(schema)
    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DATASCHEDULER,
            title: 'dataScheduler',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DATASCHEDULER_NEW}
            >
                <div>
                    <PageName name={'Create Scheduler'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createDataScheduler}
                initialValues={{
                    method: 'emailAttachment',
                    process: 'schedule',
                }}
                subscription={{ values: true }}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ToolbarForm
                            localization={localization}
                            isLoading={isLoading}
                        />
                        <Body
                            localization={localization}
                            form={form}
                            disabledField={disabledField}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default DataSchedulerCreate
