import {
    ISSUE_ISSUE,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_NEW,
    ISSUE_RACKSCAN_TICKET,
} from '~/constants/Routes'
import { Form } from 'react-final-form'
import Body from './Body'
import BreadCrumb from '~/components/BreadCrumb'
import { number, object, string } from 'yup'
import { useCallback, useMemo, useState } from 'react'
import { useServiceCenterListOptionQuery } from '~/pages/ServiceCenter/query'
import { useTeamListQuery } from '~/pages/Team/query'
import PageName from '~/components/PageName'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useCreateScanMutation } from '../mutate'
import { useTranslation } from 'react-i18next'
import { Box, Button, Stack, Toolbar as MuiToolbar } from '@mui/material'
import CancelButton from '~/components/CancelButton'
import AddScanLinePopup from './AddScanLinePopup'
import { useSnackbar } from '~/hooks/useSnackbar'
import ComponentTooltip from '~/components/ComponentTooltip'

const ScanCreate = () => {
    const localization = 'pages.issues.rackScans.scan.create'
    const [serviceCenter, setServiceCenter] = useState()
    const { showSnackbar, snackbar } = useSnackbar()
    const [openScan, setOpenScan] = useState(false)

    const { t } = useTranslation()

    const { mutate: createScan, isLoading } = useCreateScanMutation({
        showSnackbar,
    })

    const { data: serviceCenters } = useServiceCenterListOptionQuery(
        setServiceCenter,
        showSnackbar,
        {
            menuOption: true,
            userOnly: true,
        },
    )

    const { data: teams } = useTeamListQuery(
        {
            menuOption: true,
            userOnly: true,
        },
        showSnackbar,
    )

    const handleChangeTeam = useCallback(
        (event, form) => {
            if (serviceCenters) {
                const result = serviceCenters.data.filter((x) => {
                    const a = x.teams.find((y) => y.id === event)
                    return !!a
                })
                setServiceCenter(result)
                if (result && result.length > 0) {
                    form.change('service_center_id', result[0].id)
                } else {
                    form.change('service_center_id', undefined)
                }
            }
        },
        [serviceCenters],
    )

    const schema = object().shape({
        name: string().required(),
        service_center_id: number().required(
            t('validation.serviceCenterRequired'),
        ),
        team_id: number().nullable(),
    })
    const validate = useFormValidationCallback(schema)

    const serviceCenterList = useMemo(() => {
        if (serviceCenter) {
            const serviceCenterData = serviceCenter.data
                ? serviceCenter.data
                : serviceCenter
            const result = serviceCenterData.map((serviceCenter) => ({
                name: serviceCenter.name,
                value: serviceCenter.id,
            }))

            return result
        }
        return []
    }, [serviceCenter])

    const teamList = useMemo(() => {
        if (teams) {
            const result = teams.data.map((team) => ({
                name: team.name,
                value: team.id,
            }))

            return result
        }
        return []
    }, [teams])

    const submit = useCallback((payload) => createScan(payload), [createScan])

    const onSubmit = useCallback(
        (values) => {
            const linesPayload = []

            for (const [key, value] of Object.entries(values)) {
                if (key.substring(0, 2) === 'id') {
                    linesPayload.push(value)
                }
            }

            const lines = Object.values(linesPayload).filter((i) => {
                return !!i
            })

            submit({
                ...values,
                lines,
            })
        },
        [submit],
    )

    const handleStart = (values) => {
        if (values.name && values.service_center_id) {
            setOpenScan(true)
        }
    }

    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_RACKSCAN_TICKET, title: 'rackScans' },
        {
            route: ISSUE_RACKSCAN_SCAN,
            title: 'scan',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_RACKSCAN_SCAN_NEW}
            >
                <div>
                    <PageName name={'Create Scan'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={onSubmit}
                subscription={{ values: true }}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <MuiToolbar disableGutters={false}>
                            <Box sx={{ flexGrow: 1 }}></Box>
                            <Stack direction='row-reverse' spacing={2}>
                                <Button
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#76B72A',
                                    }}
                                    onClick={() => {
                                        handleStart(values)
                                    }}
                                >
                                    Start
                                </Button>
                                <CancelButton />
                            </Stack>
                        </MuiToolbar>
                        <Body
                            localization={localization}
                            serviceCenterList={serviceCenterList}
                            teamList={teamList}
                            handleChangeTeam={(event) =>
                                handleChangeTeam(event, form)
                            }
                        />
                        <AddScanLinePopup
                            openScan={openScan}
                            setOpenScan={setOpenScan}
                            form={form}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                            values={values}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default ScanCreate
