import { Alert, Box, LinearProgress, Tab, Tabs } from '@mui/material'
import { ISSUE_STATES } from '~/constants/constants'
import DiagnosticDetail from '~/pages/Diagnostic/Detail'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import IssueQualityCheck from '../QualityCheck'
import RepairComponent from '../Repair/RepairComponent'
import { IssueDetailBody } from '../components'
import { useIssueQuery } from '../query'
import { LoadingButton } from '~/components/Button'
import { useQueryClient } from 'react-query'
import { useTransitIssueState } from '~/pages/Issue/mutation'
import Triage from '~/pages/Issue/Triage'
import IssueImages from '../Images'
import usePermissions from '~/hooks/usePermission'

const tabList = [
    {
        index: 0,
        name: 'general',
    },
    {
        index: 1,
        name: 'triage',
    },
    {
        index: 2,
        name: 'diagnostics',
    },
    {
        index: 3,
        name: 'repair',
    },
    {
        index: 4,
        name: 'image',
    },
    {
        index: 5,
        name: 'qualityCheck',
    },
    {
        index: 6,
        name: 'report',
    },
]

const localization = 'pages.issues.overview'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const IssueTabs = () => {
    const queryClient = useQueryClient()
    const [value, setValue] = useState(0)
    const navigate = useNavigate()
    const { issueId } = useParams()
    const { t } = useTranslation()

    const location = useLocation()
    const { data, isLoading } = useIssueQuery(issueId)
    const diagnoseId = data?.data?.diagnostic?.id
    const { permissions } = usePermissions()

    const { mutate: transitState, isLoading: loadingTransition } =
        useTransitIssueState()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (!location.search) {
            searchParams.set('tag', '0')
            const newSearchString = searchParams.toString()
            navigate({
                pathname: window.location.pathname,
                search: newSearchString,
            })
        } else if (searchParams.has('tag')) {
            const tabIndex = parseInt(searchParams.get('tag'))

            if (tabIndex > 6 || tabIndex < 0 || isNaN(tabIndex)) {
                searchParams.set('tag', '0')
                const newSearchString = searchParams.toString()
                navigate({
                    pathname: window.location.pathname,
                    search: newSearchString,
                })
            }

            const obj = tabList.some((t) => t.index === tabIndex)
            if (obj) {
                setValue(tabIndex)
            } else {
                setValue(0)
            }
        }
    }, [location.search, navigate])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('tag', newValue)
        const newSearchString = searchParams.toString()
        navigate({
            pathname: window.location.pathname,
            search: newSearchString,
        })
    }

    const renderDiagnosticTab = useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === data?.data?.state,
        )

        if (
            stateIndex >=
            ISSUE_STATES.findIndex((state) => state === 'TRIAGE_DONE')
        ) {
            return <DiagnosticDetail {...{ diagnoseId }} />
        }

        return (
            <Alert severity='warning'>
                {t('pages.issues.overview.deny_diagnose_message')}
            </Alert>
        )
    }, [data, diagnoseId, isLoading, t])

    const renderRepairTab = useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === data?.data?.state,
        )
        if (
            stateIndex >=
            ISSUE_STATES.findIndex((state) => state === 'PARTS_READY')
        ) {
            return <RepairComponent />
        }
        if (
            stateIndex ===
            ISSUE_STATES.findIndex((state) => state === 'DIAGNOSED_NO_REPAIR')
        ) {
            return <Alert severity='info'>No parts needed to repair!</Alert>
        }

        if (
            stateIndex ===
            ISSUE_STATES.findIndex((state) => state === 'DIAGNOSED')
        ) {
            const hasRight = permissions.issue.canManagePartDelivered
            const isWmsOrdered = data?.data?.bom_state === 'WMS_ORDERED'
            if (hasRight && isWmsOrdered) {
                return (
                    <LoadingButton
                        loading={loadingTransition}
                        onClick={() => {
                            transitState(
                                {
                                    issueId,
                                    from: 'DIAGNOSED',
                                    to: 'PARTS_READY',
                                },
                                {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries([
                                            'issue',
                                            issueId,
                                        ])
                                        queryClient.invalidateQueries([
                                            'issue_logs',
                                        ])
                                    },
                                },
                            )
                        }}
                        label={t(`general.partsDelivered`)}
                    />
                )
            }
        }

        return (
            <Alert severity='warning'>
                {t('pages.issues.overview.deny_repair_message')}
            </Alert>
        )
    }, [
        data?.data?.bom_state,
        data?.data?.state,
        isLoading,
        issueId,
        loadingTransition,
        permissions.issue.canManagePartDelivered,
        queryClient,
        t,
        transitState,
    ])

    const renderQcTab = useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === data?.data?.state,
        )

        if (
            stateIndex >=
                ISSUE_STATES.findIndex((state) => state === 'REPAIRED') ||
            stateIndex ===
                ISSUE_STATES.findIndex(
                    (state) => state === 'DIAGNOSED_NO_REPAIR',
                )
        ) {
            return <IssueQualityCheck issueData={data} />
        }

        return (
            <Alert severity='warning'>
                {t('pages.issues.overview.deny_qc_message')}
            </Alert>
        )
    }, [data, isLoading, t])

    const renderImageTab = useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === data?.data?.state,
        )

        if (
            stateIndex >=
            ISSUE_STATES.findIndex((state) => state === 'WAIT_FOR_IMAGE')
        ) {
            return <IssueImages />
        }

        return (
            <Alert severity='warning'>
                {t('pages.issues.overview.deny_image_message')}
            </Alert>
        )
    }, [data, isLoading, t])

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    {tabList.map((tab) => (
                        <Tab
                            key={tab.index}
                            label={t(`${localization}.${tab.name}`)}
                            {...a11yProps(tab.index)}
                        />
                    ))}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <IssueDetailBody />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {isLoading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Triage />
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {renderDiagnosticTab}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {renderRepairTab}
            </TabPanel>
            <TabPanel value={value} index={4}>
                {renderImageTab}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {renderQcTab}
            </TabPanel>
            <TabPanel value={value} index={6}>
                <p>{t('pages.issues.overview.coming_soon')}</p>
            </TabPanel>
        </Box>
    )
}
export default IssueTabs
