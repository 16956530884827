import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PROCUREMENT, PROCUREMENT_ORDERS } from '~/constants/Routes'
import ProcurementOrderTable from './ProcurementOrderTable'

const ProcurementOrder = () => {
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_ORDERS,
            title: 'ProcurementOrders',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_ORDERS}
            >
                <div>
                    <PageName name={'Procurement Orders'} />
                </div>
            </ComponentTooltip>
            <ProcurementOrderTable />
        </>
    )
}

export default ProcurementOrder
