import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const statuses = [
    { id: '', name: 'All' },
    { id: 'active', name: 'active' },
    { id: 'pending', name: 'pending' },
    { id: 'rejected', name: 'rejected' },
    { id: 'invited', name: 'invited' },
]

const UserTableFilters = ({
    search,
    searchHandler,
    status,
    setStatus,
    onEnterSearch,
}) => {
    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Status',
                handleFunc: setStatus,
                dataSet: statuses,
                value: status,
            },
        ]
    }, [setStatus, status])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='users'
            page='roles'
        />
    )
}
UserTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    role: PropTypes.string,
    setRole: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default UserTableFilters
