import { useMutation } from 'react-query'
import procurementOrderApi from '~/api/procurementOrderApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseErrorMessageAndShow } from '~/utils/helpers'

export const useExportOrderMutation = () => {
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['procurement_order_export'],
        async (payload) => {
            const { data } = await procurementOrderApi.getOrdersExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `order_confirmation.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            retry: false,
        },
    )
}
