import ToolbarForm from '~/components/ToolbarForm'
import { cron, multipleEmail, selectStatement } from '~/constants/yupSchemas'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import Body from '../Body'
import {
    useUpdateDataSchedulerMutation,
    useUpdateExportScheduleStatusMutation,
} from '../mutation'
import PropTypes from 'prop-types'
import usePermissions from '~/hooks/usePermission'
const localization = 'pages.administrator.dataScheduler.create'
const SchedulerForm = ({ exportScheduler }) => {
    const { t } = useTranslation()
    const { permissions } = usePermissions()

    const havePermissionToEdit = useMemo(
        () => permissions.administrator.dataScheduler.canEdit,
        [permissions.administrator.dataScheduler.canEdit],
    )
    const initialValues = useMemo(() => {
        if (exportScheduler) {
            return {
                name: exportScheduler?.name,
                database: exportScheduler?.database,
                statement: exportScheduler?.statement,
                emails: exportScheduler?.email_address,
                cron: exportScheduler?.cron,
                method: exportScheduler?.email_address
                    ? 'emailAttachment'
                    : 'storage',
                process: exportScheduler.cron ? 'schedule' : 'noSchedule',
                path_token: exportScheduler?.path_token,
            }
        }
    }, [exportScheduler])

    const { mutate: updateDataScheduler, isLoading } =
        useUpdateDataSchedulerMutation()

    const { mutate: updateExportScheduleStatus } =
        useUpdateExportScheduleStatusMutation()

    const changeExportSchedulerStatus = ({ id, enabled }) => {
        updateExportScheduleStatus({
            id,
            enabled,
        })
    }

    const schema = object().shape({
        name: string().required(),
        statement: selectStatement(t).required(t('validation.selectStatement')),
        method: string().required(),
        emails: multipleEmail(t)
            .nullable()
            .when('method', {
                is: 'emailAttachment',
                then: string().required('Must enter email address'),
            }),
        cron: cron(t).nullable(),
    })

    const validate = useFormValidationCallback(schema)
    return (
        <Form
            validate={validate}
            onSubmit={updateDataScheduler}
            initialValues={initialValues}
            subscription={{ values: true }}
            render={({ handleSubmit, form, values }) => (
                <form onSubmit={handleSubmit}>
                    <ToolbarForm
                        localization={localization}
                        isLoading={isLoading}
                        exportScheduler={exportScheduler}
                        initialValues={initialValues}
                        changeExportSchedulerStatus={
                            changeExportSchedulerStatus
                        }
                        havePermissionToEdit={havePermissionToEdit}
                    />
                    <Body
                        localization={localization}
                        form={form}
                        initialValues={initialValues}
                        disabledField={!havePermissionToEdit}
                    />
                </form>
            )}
        />
    )
}

SchedulerForm.propTypes = { exportScheduler: PropTypes.object }

export default SchedulerForm
