import { useQuery } from 'react-query'
import procurementOrderApi from '~/api/procurementOrderApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseErrorMessageAndShow, removeEmptyValue } from '~/utils/helpers'

export const useProcurementOrdersQuery = (params) => {
    const { openSnackbar } = useDialogContext()
    const p = removeEmptyValue(params)
    return useQuery(
        ['procurement_orders', params],
        async () => {
            const { data } = await procurementOrderApi.getProcurementOrders(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            retry: false,
        },
    )
}

export const useSuppliersQuery = () => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['procurement_suppliers'],
        async () => {
            const { data } = await procurementOrderApi.getSuppliers()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            retry: false,
        },
    )
}

export const useOrderStatusesQuery = () => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['procurement_order_statuses'],
        async () => {
            const { data } = await procurementOrderApi.getOrderStatuses()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            retry: false,
        },
    )
}

export const useProcurementOrderLinesQuery = ({ id, ...params }) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['procurement_orders', id, params],
        async () => {
            const { data } = await procurementOrderApi.getProcurementOrderLines(
                id,
                params,
            )
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            retry: false,
            enabled: !!id,
        },
    )
}
