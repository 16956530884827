import roleApi from '~/api/acl/roleApi'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ADMINISTRATOR_ROLE } from '~/constants/Routes'
import { setSnackbar } from '~/store/snackbar/slice'

export const useUpdateMutation = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    return useMutation(
        ['role_update'],
        async (values) => {
            const permissions = values.permissions
                .filter((permission) => {
                    return permission.value
                })
                .map((permission) => {
                    return permission.name
                })

            return await roleApi.update(values.id, {
                ...values,
                permissions,
            })
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Save successful!',
                        type: 'success',
                    }),
                )
                queryClient.invalidateQueries('acl_roles_detail')

                navigate(ADMINISTRATOR_ROLE)
                return data
            },
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: error.message ? error.message : 'Save failed!',
                        type: 'error',
                    }),
                )
                navigate(ADMINISTRATOR_ROLE)
            },
            retry: false,
        },
    )
}
