import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CloseButton } from '~/components/CancelButton'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import {
    ProcurementOrderBuyerHeadCells,
    ProcurementOrderDetailHeadCells,
    ProcurementOrderLineHeadCells,
    ProcurementOrderSuplierHeadCells,
    ProcurementOrderUserInformationHeadCells,
} from '~/components/HeaderTable/OrderConfirmationHeadCells'
import PaginationTable from '~/components/PaginationTable'
import StyledModal from '~/components/StyledModal'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useProcurementOrderLinesQuery } from '../query'

const ProcurementOrderLinesTableBody = ({ lines }) => {
    return (
        <TableBody>
            {lines.length > 0 ? (
                lines.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.line_number}</TableCell>
                            <TableCell>{row.quantity ?? '-'}</TableCell>
                            <TableCell>{row.item_id}</TableCell>
                            <TableCell>{row.item_description ?? '-'}</TableCell>
                            <TableCell>
                                {row.manufacturer_item_id ?? '-'}
                            </TableCell>
                            <TableCell>{row.price}</TableCell>
                            <TableCell>{row.line_amount}</TableCell>
                            <TableCell>
                                {row.quantity_remaining ?? '-'}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ProcurementOrderLineHeadCells.length} />
            )}
        </TableBody>
    )
}

const ProcurementOrderTableBody = ({ procurementOrder }) => {
    return (
        <TableBody>
            <TableRow hover tabIndex={-1} key={procurementOrder?.order_number}>
                <TableCell>{procurementOrder.customer_name}</TableCell>
                <TableCell>{procurementOrder.to_city ?? '-'}</TableCell>
                <TableCell>{procurementOrder.amount_excl_vat}</TableCell>
                <TableCell>{procurementOrder.amount_incl_vat}</TableCell>
                <TableCell>
                    {procurementOrder.description_response_code ?? '-'}
                </TableCell>
                <TableCell>
                    <DateTimeLocale datetime={procurementOrder?.order_date} />
                </TableCell>
                <TableCell>
                    <DateTimeLocale datetime={procurementOrder?.created_at} />
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

const ProcurementOrderSuplierTableBody = ({ procurementOrder }) => {
    return (
        <TableBody>
            <TableRow hover tabIndex={-1} key={procurementOrder?.name}>
                <TableCell>{procurementOrder?.name ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.address_1 ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.postcode ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.city ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.pobox_postcode ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.phone ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.fax ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.bank_account ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.vat_number ?? '-'}</TableCell>
            </TableRow>
        </TableBody>
    )
}

const ProcurementOrderBuyerTableBody = ({ procurementOrder }) => {
    return (
        <TableBody>
            <TableRow hover tabIndex={-1} key={procurementOrder?.ZIP}>
                <TableCell>{procurementOrder?.ZIP ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.City ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.Name1 ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.Street ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.Country ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.PartnerID ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.CustContact ?? '-'}</TableCell>
            </TableRow>
        </TableBody>
    )
}

const ProcurementOrderUserInformationTableBody = ({ procurementOrder }) => {
    return (
        <TableBody>
            <TableRow hover tabIndex={-1} key={procurementOrder?.name}>
                <TableCell>{procurementOrder?.name ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.street ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.postalcode ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.city ?? '-'}</TableCell>
                <TableCell>{procurementOrder?.country ?? '-'}</TableCell>
            </TableRow>
        </TableBody>
    )
}

const ProcurementOrderDetail = ({ open, handleClose, rowData }) => {
    const id = rowData?.id
    const { t } = useTranslation()
    const localization = 'pages.procurement.procurementOrder.detail.'
    const { results, data, isLoading, page, changePageHandler } = useTable({
        query: useProcurementOrderLinesQuery,
        queryAttributes: {
            id,
            enabled: !!id,
        },
        initialStates: {
            order: 'asc',
            page: 1,
            orderBy: 'id',
        },
    })

    if (isLoading) {
        return <TableLoading />
    }

    return (
        <StyledModal
            open={open}
            handleClose={handleClose}
            disableBackdropClick={false}
            stackProps={{
                width: '1080px',
                height: '900px',
                sx: {
                    outline: 'none',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    borderRadius: 2,
                },
            }}
        >
            <Typography variant='h5'>
                {t('general.procurementOrder')} ({rowData?.order_number}){' - '}
                ({rowData?.customer_order_number}){' - '}(
                {rowData?.complete_delivery ?? 'N/A'})
            </Typography>

            <Paper sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography variant='h6' sx={{ padding: '10px' }}>
                    {t('general.procurementOrderLines')}
                </Typography>
                <Table size='small'>
                    <TableHeader
                        headCells={ProcurementOrderLineHeadCells}
                        localization={localization}
                    />
                    <ProcurementOrderLinesTableBody lines={results} />
                </Table>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='small'
                />
            </Paper>

            <Paper sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography variant='h6' sx={{ padding: '10px' }}>
                    {t('general.procurementOrder')}
                </Typography>
                <Table size='small'>
                    <TableHeader
                        headCells={ProcurementOrderDetailHeadCells}
                        localization={localization}
                    />
                    <ProcurementOrderTableBody procurementOrder={rowData} />
                </Table>
            </Paper>

            <Paper sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography variant='h6' sx={{ padding: '10px' }}>
                    {t('general.supplier')}
                </Typography>
                <Table size='small'>
                    <TableHeader
                        headCells={ProcurementOrderSuplierHeadCells}
                        localization={localization}
                    />
                    <ProcurementOrderSuplierTableBody
                        procurementOrder={rowData?.supplier}
                    />
                </Table>
            </Paper>

            <Paper sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography variant='h6' sx={{ padding: '10px' }}>
                    {t('general.userInformation')}
                </Typography>
                <Table size='small'>
                    <TableHeader
                        headCells={ProcurementOrderUserInformationHeadCells}
                        localization={localization}
                    />
                    <ProcurementOrderUserInformationTableBody
                        procurementOrder={rowData?.end_user_information}
                    />
                </Table>
            </Paper>

            <Paper sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography variant='h6' sx={{ padding: '10px' }}>
                    {t('general.buyer')}
                </Typography>
                <Table size='small'>
                    <TableHeader
                        headCells={ProcurementOrderBuyerHeadCells}
                        localization={localization}
                    />
                    <ProcurementOrderBuyerTableBody
                        procurementOrder={rowData?.buyer}
                    />
                </Table>
            </Paper>
            <Grid item>
                <CloseButton onClick={handleClose} />
            </Grid>
        </StyledModal>
    )
}

ProcurementOrderDetail.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    rowData: PropTypes.object,
}

ProcurementOrderTableBody.propTypes = {
    procurementOrder: PropTypes.object,
}

ProcurementOrderSuplierTableBody.propTypes = {
    procurementOrder: PropTypes.object,
}

ProcurementOrderBuyerTableBody.propTypes = {
    procurementOrder: PropTypes.object,
}

ProcurementOrderUserInformationTableBody.propTypes = {
    procurementOrder: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

ProcurementOrderLinesTableBody.propTypes = {
    lines: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ProcurementOrderDetail
