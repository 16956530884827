export const RoleHeadCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'guard_name',
        numeric: false,
        disablePadding: false,
        label: 'guard_name',
        orderable: true,
    },
    {
        id: 'connected_users',
        numeric: false,
        disablePadding: false,
        label: 'connectedUsers',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: true,
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updatedAt',
        orderable: true,
    },
]
