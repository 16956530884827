export const DIAGNOSTIC = '/issues/issues/:issueId/diagnostic'
export const DIAGNOSTIC_DETAIL =
    '/issues/issues/:issueId/diagnostic/:diagnosticId'
export const HOME = '/'
export const DASHBOARD = '/dashboard'

export const ISSUE_OVERVIEW = '/issues/overview'
export const ISSUE_CREATE = '/issues/create'
export const ISSUE_DETAIL = '/issues/overview/:id'
export const ISSUE_ISSUE = '/issues/issues'
export const ISSUE_ISSUE_DETAIL = '/issues/issues/:issueId'
export const ISSUE_BOM = '/issues/boms'
export const ISSUE_BOM_DETAIL = '/issues/boms/:issueId'
export const ISSUE_ISSUE_REPAIR = '/issues/issues/:id/repair'
export const ISSUE_ISSUE_QC = '/issues/issues/:id/quality-check'
export const ISSUE_DISPATCH = '/issues/dispatches'
export const ISSUE_DISPATCH_COMPONENT = '/issues/dispatches/components'
export const ISSUE_DISPATCH_IMPORT_EXPORT = '/issues/dispatches/import-export'
export const ISSUE_DISPATCH_ADD = '/issues/dispatches/add'
export const ISSUE_DISPATCH_DETAIL = '/issues/dispatches/:id'
export const ISSUE_DISPATCH_EDIT = '/issues/dispatches/:id/edit'
export const ISSUE_ARCHIVE = '/issues/archive'
export const ISSUE_ARCHIVE_IMAGE = '/issues/archive/images'

export const ISSUE_RACKSCAN_TICKET = '/issues/rackscan/tickets'
export const ISSUE_RACKSCAN_SCAN = '/issues/rackscan/scans'
export const ISSUE_RACKSCAN_SCAN_DETAIL = '/issues/rackscan/scans/:id'
export const ISSUE_RACKSCAN_SCAN_NEW = '/issues/rackscan/scans/new'

export const FLEET_OVERVIEW = '/fleet/overview'
export const FLEET_OVERVIEW_VENDOR = '/fleet/overview/vendor'
export const FLEET_OVERVIEW_WINDOW = '/fleet/overview/window'
export const FLEET_OVERVIEW_CHROME = '/fleet/overview/chrome'
export const FLEET_SERIAL = '/fleet/serials/overview'
export const FLEET_SERIAL_DETAIL = '/fleet/serials/overview/:id'
export const FLEET_SERIAL_DETAIL_SEARCH = '/fleet/serials/:serial'
export const FLEET_MODEL = '/fleet/models'
export const FLEET_MODEL_DETAIL = '/fleet/models/:id'
export const FLEET_SWAP = '/fleet/swaps'
export const FLEET_IMPORT_DEVICES = '/fleet/import_devices'

export const PART = '/part'
export const PART_COMPONENT = '/parts/components'
export const PART_COMPONENT_DETAIL = '/parts/components/:id'
export const PART_USAGE = '/parts/usage'
export const PART_PARTCODE = '/parts/partcodes'
export const PART_PRODUCT = '/parts/products'
export const PART_PRODUCT_DETAIL = '/parts/products/:id'
export const PART_DELL_STOCK = '/parts/dell_stocks'

export const KNOWLEDGE = '/knowledge/overview'

export const CLAIM = '/claims'
export const CLAIM_RENUMERATION = '/claims/renumeration_check'
export const CLAIM_INVOICE = '/claims/invoice'
export const CLAIM_DISPUTE = '/claims/disputes'
export const CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE =
    '/claims/disputes/import_dispatch_remittance'

export const PROCUREMENT = '/procurement'
export const PROCUREMENT_PURCHASEORDER = '/procurement/purchase_order'
export const PROCUREMENT_PURCHASEORDER_DETAIL =
    '/procurement/purchase_order/:id'

export const PROCUREMENT_PURCHASEORDER_LINE_DETAIL =
    '/procurement/purchase_order/:order_id/purchase_order_line/:order_line_id'

export const PROCUREMENT_ORDERS = '/procurement/orders'
export const PROCUREMENT_ORDERCONFIRMATION = '/procurement/order_confirmation'
export const PROCUREMENT_INVOICE = '/procurement/invoices'
export const PROCUREMENT_DELIVERY = '/procurement/deliveries'
export const PROCUREMENT_DELIVERY_DETAIL = '/procurement/deliveries/:id'
export const PROCUREMENT_RMA = '/procurement/rma'

export const PRODUCTION = '/production'
export const PRODUCTION_ORGANIZATION = '/production/organizations'
export const PRODUCTION_PROJECTS = '/production/projects'
export const PRODUCTION_PROJECTS_DETAIL = '/production/projects/:project_nr'
export const PRODUCTION_IMPORT_PROJECTS = '/production/projects/import'
export const PRODUCTION_ORDERS = '/production/orders'
export const PRODUCTION_ORDERS_DETAIL =
    '/production/orders/:production_order_nr'
export const PRODUCTION_SYSTEMIMAGE = '/production/images'
export const PRODUCTION_IMAGES_DETAIL = '/production/images/:id'
export const PRODUCTION_ADD_ONS = '/production/add_ons'
export const PRODUCTION_ADD_ONS_DETAIL = '/production/add_ons/:id'
export const PRODUCTION_SERVERS = '/production/servers'
export const PRODUCTION_SERVERS_DETAIL = '/production/servers/:id'

export const ADMINISTRATOR = '/admin'
export const ADMINISTRATOR_USER = '/admin/users'
export const ADMINISTRATOR_USER_DETAIL = '/admin/users/:id'
export const ADMINISTRATOR_USER_DISABLED = '/admin/users/disabled'
export const ADMINISTRATOR_USER_DISABLED_DETAIL = '/admin/users/disabled/:id'
export const ADMINISTRATOR_ROLE = '/admin/roles'
export const ADMINISTRATOR_ROLE_DETAIL = '/admin/roles/:id'
export const ADMINISTRATOR_ROLE_CREATE = '/admin/roles/create'

export const ADMINISTRATOR_SYSTEM = '/admin/system'
export const ADMINISTRATOR_SYSTEM_ERRORLOG = '/admin/system/error_log'
export const ADMINISTRATOR_SYSTEM_API = '/admin/system/api'
export const ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT =
    '/admin/system/service_account'
export const ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL =
    '/admin/system/service_account/:id'
export const ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE =
    '/admin/system/service_account/create'

export const ADMINISTRATOR_DEFINITION = '/admin/definition'

export const ADMINISTRATOR_DEFINITION_CAUSE = '/admin/definition/causes'
export const ADMINISTRATOR_DEFINITION_CAUSE_NEW = '/admin/definition/causes/new'
export const ADMINISTRATOR_DEFINITION_CAUSE_EDIT =
    '/admin/definition/causes/:id'
export const ADMINISTRATOR_NAMING_TAG = '/admin/system/naming_tags'
export const ADMINISTRATOR_NAMING_TAG_DETAIL = '/admin/system/naming_tags/:id'
export const ADMINISTRATOR_NAMING_TAG_CREATE =
    '/admin/system/naming_tags/create'
export const ADMINISTRATOR_NAMING_TAG_RULE_DETAIL =
    '/admin/system/naming_tags/rule/:id'
export const ADMINISTRATOR_NAMING_TAG_RULE_CREATE =
    '/admin/system/naming_tags/rule/create'

export const ADMINISTRATOR_DEFINITION_REGIONS = '/admin/definition/regions'
export const ADMINISTRATOR_DEFINITION_REGIONS_DETAIL =
    '/admin/definition/regions/:id'
export const ADMINISTRATOR_DEFINITION_REGIONS_NEW =
    '/admin/definition/regions/new'
export const ADMINISTRATOR_DEFINITION_REGIONS_EDIT =
    '/admin/definition/regions/:id/edit'

export const ADMINISTRATOR_DEFINITION_SERVICECENTER =
    '/admin/definition/service_centers'

export const ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL =
    '/admin/definition/service_centers/:id'
export const ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW =
    '/admin/definition/service_centers/new'
export const ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT =
    '/admin/definition/service_centers/:id/edit'

export const ADMINISTRATOR_DEFINITION_TEAM = '/admin/definition/teams'
export const ADMINISTRATOR_DEFINITION_TEAM_DETAIL =
    '/admin/definition/teams/:id'
export const ADMINISTRATOR_DEFINITION_TEAM_NEW = '/admin/definition/teams/new'
export const ADMINISTRATOR_DEFINITION_TEAM_EDIT =
    '/admin/definition/teams/:id/edit'

export const ADMINISTRATOR_DEFINITION_WORKBENCH =
    '/admin/definition/workbenches'
export const ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL =
    '/admin/definition/workbenches/:id'
export const ADMINISTRATOR_DEFINITION_WORKBENCH_NEW =
    '/admin/definition/workbenches/new'

export const ADMINISTRATOR_TOOLTIP = '/admin/definition/tooltips'
export const ADMINISTRATOR_TOOLTIP_CREATE = '/admin/definition/tooltips/create'
export const ADMINISTRATOR_TOOLTIP_DETAIL = '/admin/definition/tooltips/:id'

export const USER_SETTING = '/user/:id/setting'
export const ADMINISTRATOR_DATASCHEDULER = '/admin/data_schedulers'
export const ADMINISTRATOR_DATASCHEDULER_NEW = '/admin/data_schedulers/new'
export const ADMINISTRATOR_DATASCHEDULER_DETAIL = '/admin/data_schedulers/:id'
export const ADMINISTRATOR_TEST_DATA_DIAGNOSE = '/admin/test-data/diagnose'
export const ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT =
    '/admin/test-data/dispatch-shipment'
