import { useSelector } from 'react-redux'
import {
    ADMINISTRATOR_USER_IMPERSONATE,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_VIEW,
    PERMISSION_ADMINISTRATOR_DEFINITION_ADDRESSES_CREATE,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
    PERMISSION_FLEET_SERIAL_ADD,
    PERMISSION_FLEET_SERIAL_UPDATE,
    PERMISSION_ISSUE_BOM_EDIT,
    PERMISSION_ISSUE_BOM_EDIT_STOCK_LABEL,
    PERMISSION_ISSUE_BOM_OVERVIEW,
    PERMISSION_ISSUE_CLOSE,
    PERMISSION_ISSUE_DIAGNOSTICS_ACCIDENTAL_DAMAGE,
    PERMISSION_ISSUE_DIAGNOSTICS_ADD_ADDITIONAL_PARTS,
    PERMISSION_ISSUE_DIAGNOSTICS_MALICIOUS_DAMAGE,
    PERMISSION_ISSUE_DISPATCH_CREATE,
    PERMISSION_ISSUE_EXPERT,
    PERMISSION_ISSUE_IMAGES_CANCEL,
    PERMISSION_ISSUE_IMAGES_PROCEED,
    PERMISSION_ISSUE_PART_DELIVERED,
    PERMISSION_ISSUE_QC_OVERRULE,
    PERMISSION_ISSUE_RACKSCANS_SCAN,
    PERMISSION_PART_PRODUCT_LINK_COMPONENTS,
    PERMISSION_PROCUREMENT,
    SUPER_ADMIN,
} from '~/constants/permissions'
import { selectUserPermissions } from '~/store/auth/selector'

const usePermissions = () => {
    const userPermissions = useSelector(selectUserPermissions)

    const hasPermissions = (requiredPermissions, canAny = true) => {
        if (userPermissions.includes(SUPER_ADMIN)) {
            return true
        }

        if (Array.isArray(requiredPermissions)) {
            return canAny
                ? !!requiredPermissions.some((permission) =>
                      userPermissions.includes(permission),
                  )
                : !!requiredPermissions.every((permission) =>
                      userPermissions.includes(permission),
                  )
        }

        return !!userPermissions.includes(requiredPermissions)
    }

    const permissions = {
        isSuperAdmin: hasPermissions(SUPER_ADMIN),
        issue: {
            canCloseIssue: hasPermissions([PERMISSION_ISSUE_CLOSE]),
            canManageExpertIssue: hasPermissions([PERMISSION_ISSUE_EXPERT]),
            canManagePartDelivered: hasPermissions([
                PERMISSION_ISSUE_PART_DELIVERED,
            ]),
            canOverruleQC: hasPermissions(
                [PERMISSION_ISSUE_QC_OVERRULE, PERMISSION_ISSUE_EXPERT],
                false,
            ),
            diagnostic: {
                canCheckAccidentalDamge: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_ACCIDENTAL_DAMAGE,
                ]),
                canCheckMaliciousDamge: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_MALICIOUS_DAMAGE,
                ]),
                canAddAdditionalParts: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_ADD_ADDITIONAL_PARTS,
                ]),
            },
            bom: {
                canViewBom: hasPermissions([PERMISSION_ISSUE_BOM_OVERVIEW]),
                canEditBom: hasPermissions([PERMISSION_ISSUE_BOM_EDIT]),
                canEditBomStockLabel: hasPermissions([
                    PERMISSION_ISSUE_BOM_EDIT_STOCK_LABEL,
                ]),
            },
            dispatch: {
                canCreateDispatch: hasPermissions([
                    PERMISSION_ISSUE_DISPATCH_CREATE,
                ]),
            },
            images: {
                canCancelImageIssue: hasPermissions([
                    PERMISSION_ISSUE_IMAGES_CANCEL,
                ]),
                canProceedImageIssue: hasPermissions([
                    PERMISSION_ISSUE_IMAGES_PROCEED,
                ]),
            },
            rackScan: {
                canCreateScan: hasPermissions([
                    PERMISSION_ISSUE_RACKSCANS_SCAN,
                ]),
            },
        },
        parts: {
            products: {
                canLinkComponent: hasPermissions([
                    PERMISSION_PART_PRODUCT_LINK_COMPONENTS,
                ]),
            },
        },
        fleet: {
            canAddSerials: hasPermissions([PERMISSION_FLEET_SERIAL_ADD]),
            canUpdateSerials: hasPermissions([PERMISSION_FLEET_SERIAL_UPDATE]),
        },
        administrator: {
            serviceCenter: {
                canEditServiceCenter: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                ]),
            },
            definitions: {
                canCreateAddress: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_ADDRESSES_CREATE,
                ]),
                canCreateCauses: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
                ]),
            },
            user: {
                canImpersonateUser: hasPermissions([
                    ADMINISTRATOR_USER_IMPERSONATE,
                ]),
            },
            dataScheduler: {
                canCreate: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
                ]),
                canEdit: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
                ]),
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_VIEW,
                ]),
            },
        },
        procurement: {
            canViewProcument: hasPermissions([PERMISSION_PROCUREMENT]),
        },
    }

    return {
        permissions,
    }
}

export default usePermissions
