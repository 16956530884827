import {
    Box,
    CircularProgress,
    LinearProgress,
    Paper,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { LoadingButton } from '~/components/Button'
import ImageList from '~/components/Image/ImageList'
import { StyledPaper } from '~/components/StyledPaper'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import SectionTitle from '~/pages/Diagnostic/components/SectionTitle'
import {
    useIssueDiagnoseQuery,
    usePictureQuery,
    useTicketDetailsQuery,
} from '~/pages/Diagnostic/query'
import CheckboxesSection from '../../Diagnostic/CheckboxesSection'
import {
    useAdvanceRepairMutation,
    useSaveRepairTechnicianCommentMutation,
} from '../mutation'
import PartToChangeTable from './Component/PartToChangeTable'

const locale = window.localStorage.getItem('LOCALE')

const RepairPage = ({
    issueData,
    issueId,
    finished,
    setOpenRemovePart,
    techComment,
    setTechComment,
}) => {
    const LOCALIZATION = 'pages.issues.repair.'
    const { t } = useTranslation()
    const { data, isLoading } = usePictureQuery(issueId)

    const { data: ticketData } = useTicketDetailsQuery(issueId)

    const { mutate: saveRepairComment, isLoading: isSavingComment } =
        useSaveRepairTechnicianCommentMutation()

    const { mutate: advanceRepair } = useAdvanceRepairMutation()

    const queryClient = useQueryClient()

    const diagnoseId = issueData?.data?.diagnostic?.id
    const { data: dataDiagnose, isFetched: isDiagnoseFetched } =
        useIssueDiagnoseQuery({
            id: diagnoseId,
        })

    const images = useMemo(() => {
        return (
            data?.data &&
            data?.data.map((image) => {
                return {
                    src: image.preview_url,
                    id: image.id,
                    created_at: image.created_at,
                }
            })
        )
    }, [data?.data])

    const handleUpdateComment = useMemo(
        () =>
            _.debounce((comment) => {
                saveRepairComment({ issueId, comment })
            }, 800),
        [saveRepairComment, issueId],
    )

    const onCommentChange = (e) => {
        const newComment = e.target.value

        setTechComment(newComment)

        handleUpdateComment(newComment)
    }

    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    const finishRepairForm = () => {
        if (issueData?.data?.repair?.current_step === 3) {
            setOpenRemovePart(true)
        } else {
            advanceRepair(
                { issueId, step: 3 },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['issue', issueId])
                        queryClient.invalidateQueries(['issue_logs'])
                        setOpenRemovePart(true)
                    },
                },
            )
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '8px 8px',
                    mb: '8px',
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        gridRowStart: 1,
                        gridRowEnd: 3,
                    }}
                >
                    <Paper sx={{ height: '100%' }} variant='outlined'>
                        <StyledPaper variant='outlined'>
                            <TicketDetails data={ticketData} />
                        </StyledPaper>
                    </Paper>
                </Box>
                <Box align='center' sx={{ height: '100%' }}>
                    {!isLoading ? (
                        <Paper sx={{ p: 2, height: '100%' }} variant='outlined'>
                            <SectionTitle
                                title={t(`${LOCALIZATION}pictures`)}
                            />
                            <ImageList
                                images={images}
                                showRemoveImage={false}
                                showRemoveCurrentImage={false}
                            />
                        </Paper>
                    ) : (
                        <CircularProgress thickness={3} size={20} />
                    )}
                </Box>

                <Box
                    sx={{
                        height: '100%',
                    }}
                >
                    <Paper sx={{ height: '100%' }} variant='outlined'>
                        <StyledPaper variant='outlined'>
                            <SectionTitle
                                title={t(`${LOCALIZATION}softwareDiagnostics`)}
                            />
                            <Box sx={{ padding: 2 }}>
                                <Typography>
                                    {t(`${LOCALIZATION}biosVersion`)}: 0.0.1
                                </Typography>
                                <Typography>
                                    {t(`${LOCALIZATION}batteryState`)}: good
                                </Typography>
                            </Box>
                        </StyledPaper>
                    </Paper>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Paper sx={{ height: '100%' }} variant='outlined'>
                        <StyledPaper variant='outlined'>
                            {isDiagnoseFetched && dataDiagnose && (
                                <Form
                                    onSubmit={() => {}}
                                    initialValues={dataDiagnose}
                                    render={({ form }) => {
                                        return (
                                            <CheckboxesSection
                                                {...{
                                                    setShowDiagnosticsSection:
                                                        () => {},
                                                    setShowAccidentalCondition:
                                                        () => {},
                                                    setDisabledNextButton:
                                                        () => {},
                                                    form,
                                                    values: dataDiagnose,
                                                    finishDiagnostic: true,
                                                    setShowMaliciousCondition:
                                                        () => {},
                                                    setIsWaterTotalDamaged:
                                                        () => {},
                                                    setDiagnosticSaveButton:
                                                        () => {},
                                                }}
                                            />
                                        )
                                    }}
                                />
                            )}
                        </StyledPaper>
                    </Paper>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Paper
                        sx={{ height: '100%', px: 2, py: 1 }}
                        variant='outlined'
                    >
                        <SectionTitle title={t(`${LOCALIZATION}rootCauses`)} />
                        {issueData.data.causes.map((cause) => {
                            return (
                                <div key={cause.id}>
                                    {'- ' + cause.name[locale ?? 'en']}
                                </div>
                            )
                        })}
                    </Paper>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '8px 8px',
                }}
            >
                <Box sx={{ height: '100%' }}>
                    <Paper
                        sx={{ height: '100%', px: 2, py: 1 }}
                        variant='outlined'
                    >
                        <SectionTitle
                            title={t(`${LOCALIZATION}diagnosticDescription`)}
                        />

                        <TextareaAutosize
                            value={
                                issueData.data.causes.reduce((val, b) => {
                                    return (
                                        val +
                                        `${
                                            locale ? b.name[locale] : b.name
                                        } : ${b.notes ?? 'NULL'}\n`
                                    )
                                }, '') || ''
                            }
                            minRows={5}
                            maxRows={5}
                            style={{
                                width: '100%',
                            }}
                            disabled
                        />
                    </Paper>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Paper
                        sx={{ height: '100%', px: 2, py: 1 }}
                        variant='outlined'
                    >
                        <SectionTitle
                            title={t(`${LOCALIZATION}technicianComments`)}
                        />
                        <TextareaAutosize
                            value={issueData.data.diagnostic.comments || ''}
                            minRows={5}
                            maxRows={5}
                            style={{
                                width: '100%',
                            }}
                            disabled
                        />
                    </Paper>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Paper
                        sx={{ height: '100%', px: 2, py: 1 }}
                        variant='outlined'
                    >
                        <SectionTitle
                            title={t(`${LOCALIZATION}partsToChange`)}
                        />
                        <PartToChangeTable
                            issueId={issueId}
                            finished={finished}
                            getAll={false}
                        />
                    </Paper>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Paper
                        sx={{ height: '100%', px: 2, py: 1 }}
                        variant='outlined'
                    >
                        <SectionTitle
                            title={t(`${LOCALIZATION}technicianCommentsRepair`)}
                        />
                        <TextField
                            multiline
                            disabled={finished}
                            value={techComment}
                            onChange={onCommentChange}
                            minRows={5}
                            maxRows={5}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Paper>
                </Box>
            </Box>

            {!finished && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.finish`)}
                        onClick={finishRepairForm}
                        loading={isSavingComment}
                    ></LoadingButton>
                </Box>
            )}
        </>
    )
}

RepairPage.propTypes = {
    issueData: PropTypes.object,
    issueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    finished: PropTypes.bool,
    setOpenRemovePart: PropTypes.func,
    techComment: PropTypes.string,
    setTechComment: PropTypes.func,
}

RepairPage.defaultProps = {
    issueData: {},
    issueId: '',
    finished: false,
    setOpenRemovePart: () => {},
    techComment: '',
    setTechComment: () => {},
}

export default RepairPage
