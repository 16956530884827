import { Box, Card, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import CustomCardHeader from '~/components/CustomCardHeader'
import { useTranslation } from 'react-i18next'
import UsersTable from './UsersTable'

const RoleUser = ({ localization }) => {
    const { t } = useTranslation()

    return (
        <Grid container spacing={2}>
            <Stack
                direction='column'
                spacing={3}
                sx={{ width: '100%', paddingBottom: 0 }}
            >
                <Card>
                    <CustomCardHeader
                        title={t(`${localization}.connectedUsers`)}
                        action={
                            <Box
                                direction='row'
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                    maxHeight: '50px',
                                }}
                            />
                        }
                    />
                    <UsersTable />
                </Card>
            </Stack>
        </Grid>
    )
}
RoleUser.propTypes = { localization: PropTypes.string }

export default RoleUser
