import { axiosClient } from '../base'

class RoleApi {
    getRoles = (params) => axiosClient.get('api/acl/roles', { params })
    getRolesByGuard = (params) =>
        axiosClient.get(`api/acl/roles/guard/${params}`)

    getRole = (id) => axiosClient.get(`api/acl/roles/${id}`)

    update = (id, params) =>
        axiosClient.put('api/acl/roles/' + params.id, { ...params })

    create = (params) => axiosClient.post('api/acl/roles', { ...params })

    getUsersOfRole = (id, params) =>
        axiosClient.get(`api/acl/roles/users/${id}`, { params })
}
export default new RoleApi()
