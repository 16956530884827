import Loop from '@mui/icons-material/Loop'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const spinAnimation = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
        '100%': {
            transform: 'scaleX(-1) rotate(0deg)',
        },
        '0%': {
            transform: 'scaleX(-1) rotate(360deg)',
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    rotateIcon: {
        transform: 'scaleX(-1) rotate(90deg)',
    },
}))

const LoopIcon = ({ isFetching }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Tooltip
            placement='top'
            arrow
            title={`${t(`general.refetchIssuePage`)}`}
        >
            <Loop
                sx={isFetching ? spinAnimation : {}}
                className={classes.rotateIcon}
            />
        </Tooltip>
    )
}

LoopIcon.defaultProps = {
    isFetching: false,
}

LoopIcon.propTypes = {
    isFetching: PropTypes.bool,
}

export default LoopIcon
