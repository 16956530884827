import { useTranslation } from 'react-i18next'
import Search from '../../components/Search/Search'
import { useCallback, useState } from 'react'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material'
import { useIssueScanSerialDashboardMutation } from '../Issue/mutation'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    ISSUE_DISPATCH_ADD,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_ISSUE_DETAIL,
} from '~/constants/Routes'
import { useDispatchScanSerialDashboardMutation } from '../Dispatch/mutate'
import { parseApiErrorMessage, route } from '~/utils/helpers'
import StyledModal from '~/components/StyledModal'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import ToolBarButton from '~/components/ToolBarButton'
import { ChangeCircle, Close } from '@mui/icons-material'
import { useLoginUserSwitchActiveTeamMutation } from '../UserDetail/mutate'

const Home = () => {
    const key = 'dashboard-scan-serial'
    const location = useLocation()
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const [action, setAction] = useState(localStorage.getItem(key) || '')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [targetTeam, setTargetTeam] = useState(null)
    const [deviceSerial, setDeviceSerial] = useState(null)

    const handleChange = (event) => {
        setAction(event.target.value)
        localStorage.setItem(key, event.target.value)
    }

    const { mutate: scanSerialForIssue } = useIssueScanSerialDashboardMutation()
    const { mutate: scanSerialForDispatch } =
        useDispatchScanSerialDashboardMutation()

    const onError = useCallback(
        (e) => {
            const errorData = e?.response?.data
            if (errorData?.error_code === 'SWITCH_TEAM') {
                setTargetTeam(errorData?.team)
                setError(parseApiErrorMessage(e))
                setOpen(true)
                return
            }

            if (
                errorData.error_code === 'CONTACT_SUPERVISOR' ||
                errorData.error_code === 'NOT_FOUND_SC' ||
                !errorData.error_code
            ) {
                openSnackbar({
                    message: parseApiErrorMessage(e),
                    type: 'error',
                })
            } else {
                openSnackbar({
                    message: t(
                        `message.dashboard.scanSerial.${errorData.code}`,
                    ),
                    type: 'error',
                })
            }
        },
        [openSnackbar, t],
    )

    const handleEnterSearch = (event) => {
        if (event.key === 'Enter') {
            const searchParams = new URLSearchParams(location.search)

            const serial = event.target.value
            setDeviceSerial(serial)
            if (action === 'issue') {
                scanSerialForIssue(
                    { serial },
                    {
                        onSuccess: (data) => {
                            searchParams.set('tag', data.tag)
                            searchParams.set('serial', data.serial)

                            navigate({
                                pathname: ISSUE_ISSUE_DETAIL.replace(
                                    ':issueId',
                                    data.issue_id,
                                ),
                                search: searchParams.toString(),
                            })
                        },
                        onError,
                    },
                )
            } else if (action === 'dispatch') {
                scanSerialForDispatch(
                    { serial },
                    {
                        onSuccess: (data) => {
                            const statesCreatingNew = data?.states_creating_new
                            const currentState = data?.dispatch?.state

                            if (
                                (typeof statesCreatingNew === 'undefined' &&
                                    typeof currentState === 'undefined' &&
                                    data.code === 'createNewDispatch') ||
                                statesCreatingNew.includes(currentState)
                            ) {
                                searchParams.set('serial', data.dispatch.serial)
                                navigate({
                                    pathname: ISSUE_DISPATCH_ADD,
                                    search: searchParams.toString(),
                                })
                            } else {
                                navigate({
                                    pathname: ISSUE_DISPATCH_DETAIL.replace(
                                        ':id',
                                        data.dispatch.id,
                                    ),
                                    search: searchParams.toString(),
                                })
                            }
                        },
                        onError,
                    },
                )
            }
        }
    }

    const handleCloseModal = () => {
        setOpen(false)
        setError(null)
        setTargetTeam(null)
    }

    const { mutate: activeTeam } = useLoginUserSwitchActiveTeamMutation()
    const handleSwitchTeam = async () => {
        await activeTeam({ team_id: targetTeam?.id })
        handleScanForIssueAfterActiveTeam()
    }
    const handleScanForIssueAfterActiveTeam = () => {
        const searchParams = new URLSearchParams(location.search)
        scanSerialForIssue(
            { serial: deviceSerial },
            {
                onSuccess: (data) => {
                    searchParams.set('tag', data.tag)
                    searchParams.set('serial', data.serial)

                    navigate({
                        pathname: route(ISSUE_ISSUE_DETAIL, {
                            issueId: data.issue_id,
                        }),
                        search: searchParams.toString(),
                    })
                },
                onError,
            },
        )

        setTargetTeam(null)
        setError(null)
        setOpen(false)
    }
    return (
        <>
            <Stack spacing={2}>
                <Box>
                    <FormControl sx={{ width: 240 }}>
                        <InputLabel>Scan action</InputLabel>
                        <Select
                            value={action}
                            label='Scan action'
                            onChange={handleChange}
                        >
                            <MenuItem value={'issue'}>
                                Open/create Issue
                            </MenuItem>
                            <MenuItem value={'dispatch'}>
                                Open/create Dispatch
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <Search
                        label={'Scan Serial'}
                        autoFocus
                        onKeyPress={handleEnterSearch}
                    />
                </Box>
            </Stack>
            <StyledModal
                open={open}
                handleClose={handleCloseModal}
                stackStyles={{ width: 'auto' }}
                stackProps={{
                    sx: {
                        border: 0,
                        outline: 'none',
                    },
                }}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    style={{ border: '0px' }}
                >
                    {error && <ErrorMessageInput>{error}</ErrorMessageInput>}

                    <ToolBarButton
                        icon={<Close />}
                        name={t(`pages.issues.overview.cancel`)}
                        handleAction={handleCloseModal}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#ccc',
                        }}
                    />
                    <ToolBarButton
                        icon={<ChangeCircle />}
                        name={
                            t(`pages.issues.overview.changeTeam`) +
                            ' ' +
                            targetTeam?.name
                        }
                        handleAction={handleSwitchTeam}
                    />
                </Grid>
            </StyledModal>
        </>
    )
}

export default Home
