import teamApi from '~/api/teamApi'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setTeam } from '~/store/team/slice'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useTeamListQuery = (params, showSnackbar = () => {}) => {
    return useQuery(
        ['teams', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await teamApi.getTeams(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: (e) => {
                showSnackbar(parseApiErrorMessage(e), 'error')
            },
            retry: false,
        },
    )
}

export const useGetTeamDetailQuery = (id) => {
    const dispatch = useDispatch()
    return useQuery(
        ['team_detail', id],
        async () => {
            const { data } = await teamApi.getTeamDetail(id)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setTeam(data))
            },
        },
    )
}

export const useGetUsersOfTeamQuery = (id) => {
    return useQuery(
        ['users_of_team', id],
        async () => {
            const { data } = await teamApi.getUsersOfTeam(id)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useGetUsersNotExistInTeamQuery = (id) => {
    return useQuery(
        ['users_not_exist_team', id],
        async () => {
            const { data } = await teamApi.getUsersNotExistInTeam(id)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}
