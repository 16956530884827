import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { RoleHeadCells } from '~/components/HeaderTable/RoleHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useRolesQuery } from './query'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ReferencedEntities from '~/components/ReferencedEntities'

const Row = (row) => {
    return (
        <TableRow hover tabIndex={-1} key={row.row.id}>
            <ReferencedEntities
                dataType='role-id'
                isTableCell
                dataIdentifier={row?.row?.id}
                dataDisplay={row?.row?.name}
            />
            <TableCell component='th' scope='row'>
                <Typography variant='body2'>{row.row.guard_name}</Typography>
            </TableCell>
            <ReferencedEntities
                dataType='role-id-users'
                isTableCell
                dataIdentifier={row?.row?.id}
                dataDisplay={row?.row?.users_count}
            />
            <TableCell component='th' scope='row'>
                <DateTimeLocale datetime={row?.row?.created_at} />
            </TableCell>
            <TableCell component='th' scope='row'>
                <DateTimeLocale datetime={row?.row?.updated_at} />
            </TableCell>
        </TableRow>
    )
}

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={RoleHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row) => {
                    return <Row row={row} key={row.id} />
                })
            ) : (
                <DataNotFound colSpan={RoleHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.array,
}

const Table = () => {
    const {
        results,
        data,
        isLoading,
        order,
        page,
        orderBy,
        sortHandler,
        changePageHandler,
    } = useTable({
        query: useRolesQuery,
        initialStates: {
            order: 'asc',
            page: 1,
            orderBy: 'name',
        },
    })

    return (
        <Paper>
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={RoleHeadCells}
                        localization={'pages.administrator.roles.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
